export const glossary = [
	{
		id: "palaeontologist",
		name: "palaeontologist",
		desc: "palaeontologistDesc",
		unlocks: ["nutons", "frontal", "goldenPaddle", "reuviau"],
		unlock: "",
	},
	{
		id: "archaeologist",
		name: "archaeologist",
		desc: "archaeologistDesc",
		unlocks: ["thermalBaths"],
		unlock: "",
	},
	{
		id: "geologist",
		name: "geologist",
		desc: "geologistDesc",
		unlocks: ["goldenPaddle"],
		unlock: "",
	},
	{
		id: "anthropologist",
		name: "anthropologist",
		desc: "anthropologistDesc",
		unlocks: ["thermalBaths"],
		unlock: "",
	},
	{
		id: "sediment",
		name: "sediment",
		desc: "sedimentDesc",
		unlocks: ["goldenPaddle"],
		unlock: "",
	},
	{
		id: "experimentalArchaeology",
		name: "experimentalArchaeology",
		desc: "experimentalArchaeologyDesc",
		unlocks: ["thermalBaths"],
		unlock: "",
	},
	{
		id: "necropolis",
		name: "necropolis",
		desc: "necropolisDesc",
		unlocks: ["thermalBaths"],
		unlock: "",
	},
	{
		id: "hypocaust",
		name: "hypocaust",
		desc: "hypocaustDesc",
		unlocks: ["thermalBaths"],
		unlock: "",
	},
	{
		id: "darwinism",
		name: "darwinism",
		desc: "darwinismDesc",
		unlocks: ["frontal"],
		unlock: "",
	},
	{
		id: "prospecting",
		name: "prospecting",
		desc: "prospectingDesc",
		unlocks: ["thermalBaths"],
		unlock: "",
	},
	{
		id: "ossuary",
		name: "ossuary",
		desc: "ossuaryDesc",
		unlocks: ["frontal"],
		unlock: "",
	},

	{
		id: "palaeolithic",
		name: "palaeolithic",
		desc: "palaeolithicDesc",
		unlocks: [],
		unlock: "",
	},
	{
		id: "magdalenian",
		name: "magdalenian",
		desc: "magdalenianDesc",
		unlocks: ["nutons", "frontal"],
		unlock: "",
	},
	{
		id: "neolithic",
		name: "neolithic",
		desc: "neolithicDesc",
		unlocks: ["frontal"],
		unlock: "",
	},
	{
		id: "antiquity",
		name: "antiquity",
		desc: "antiquityDesc",
		unlocks: ["hauterecenne"],
		unlock: "",
	},
	{
		id: "lateRoman",
		name: "lateRoman",
		desc: "lateRomanDesc",
		unlocks: ["hauterecenne"],
		unlock: "",
	},
	{
		id: "classicalMiddleAges",
		name: "classicalMiddleAges",
		desc: "classicalMiddleAgesDesc",
		unlocks: ["hauterecenne"],
		unlock: "",
	},
	{
		id: "highMiddleAges",
		name: "highMiddleAges",
		desc: "highMiddleAgesDesc",
		unlocks: ["hauterecenne"],
		unlock: "",
	},
];
