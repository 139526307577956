// React
import { createReducer } from "@reduxjs/toolkit";
// Types
import {
	GAME_POISRESET,
	GAME_POISPROGRESS,
	GAME_SETFOUNDPOI,
	GAME_GLOSSARYPROGRESS,
	GAME_SETFOUNDGLOSSARY,
	GAME_SETPLACEFOUNDGLOSSARY,
	IAction,
} from "../actions/types";
// Imports
import { pois } from "../data/pois";
import { glossary } from "../data/glossary";

export interface IGameState {
	pois: Array<any>;
	glossary: Array<any>;
}

const initialState: IGameState = {
	pois: pois.map((p: any) => {
		return {
			...p,
			found: false,
		};
	}),
	glossary: glossary.map((g: any) => {
		return {
			...g,
			found: false,
		};
	}),
};

export const reducer = createReducer(initialState, (builder: any) => {
	builder.addCase(GAME_POISRESET, (state: any, action: IAction) => {
		state.pois = initialState.pois;
		state.glossary = initialState.glossary;
	});
	builder.addCase(GAME_POISPROGRESS, (state: any, action: IAction) => {
		let poiToStore: any = [...pois];

		console.log("red poi", action.payload);
		for (let poi of poiToStore) {
			poi.found = action.payload.find((p: any) => p.id === poi.id).found;
		}
		state.pois = poiToStore;
	});
	builder.addCase(GAME_SETFOUNDPOI, (state: any, action: IAction) => {
		if (!state.pois.find((p: any) => p.id === action.payload)) {
			console.log("Trying to discover an inexistant word!");
		} else {
			console.log("Found poi", action.payload);
			state.pois.find((p: any) => p.id === action.payload).found = true;
		}
	});

	builder.addCase(GAME_GLOSSARYPROGRESS, (state: any, action: IAction) => {
		let glossaryToStore: any = [...glossary];
		for (let gts of glossaryToStore) {
			gts.found = action.payload.find((g: any) => g.id === gts.id).found;
		}
		state.glossary = glossaryToStore;
	});
	builder.addCase(GAME_SETFOUNDGLOSSARY, (state: any, action: IAction) => {
		if (!state.glossary.find((g: any) => g.id === action.payload)) {
			console.log("Trying to discover an inexistant word!");
		} else {
			state.glossary.find((g: any) => g.id === action.payload).found = true;
		}
	});
	builder.addCase(GAME_SETPLACEFOUNDGLOSSARY, (state: any, action: IAction) => {
		if (!state.glossary.find((g: any) => g.id === action.payload[0])) {
			console.log("Trying to link a poi to an inexistant word!");
		} else {
			if (
				state.glossary.find((g: any) => g.id === action.payload[0]).unlock ===
				""
			) {
				state.glossary.find((g: any) => g.id === action.payload[0]).unlock =
					action.payload[1];
			}
		}
	});
});
