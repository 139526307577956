// Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";
// Style
import "./map.css";
import "./ui.css";
import { GetTrad } from "../Partials/GetTrad";
import { useAuthorizations } from "src/hooks/useAuthorizations";

export const Ui = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const { askAuthorization } = useAuthorizations();

	return (
		<div className='ui'>
			{["go"].includes(props.page) && (
				<div className='ui-top'>
					<div
						className='ui-top-button ui-top-button-help ui-top-button-shadow'
						onClick={() => props.displayCreditsPage(true)}
					></div>
					<div
						className='ui-top-button ui-top-button-target ui-top-button-shadow'
						onClick={() => askAuthorization()}
					></div>
				</div>
			)}
			<div className='ui-bottom'>
				<div className='ui-bottom-2'>
					<div
						className={
							"ui-bottom-button" +
							(props.page === "timeline" ? " ui-bottom-button-selected" : "")
						}
						onClick={() => props.setPage("timeline")}
					>
						<GetTrad lang={language} value='timeline' />
					</div>
					<div
						className={
							"ui-bottom-button" +
							(props.page === "go" ? " ui-bottom-button-selected" : "")
						}
						onClick={() => props.setPage("go")}
					>
						<GetTrad lang={language} value='map' />
					</div>
					<div
						className={
							"ui-bottom-button" +
							(props.page === "glossary" ? " ui-bottom-button-selected" : "")
						}
						onClick={() => props.setPage("glossary")}
					>
						<GetTrad lang={language} value='glossary' />
					</div>
				</div>
			</div>
		</div>
	);
};
