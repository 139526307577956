// Redux
import { useSelector } from "react-redux";
import { IRootState } from "../../../reducers";
// Cookies
import { useCookies } from "react-cookie";
// CSS
import "./cookies.css";
// Utils
import { GetTrad } from "../GetTrad";

export const Cookies = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [cookies, setCookie] = useCookies(["cookiesAllowed"]);

	let goTo = () => {
		props.displayPrivatePolicyPage(true);
	};

	return (
		<div className='cookies'>
			<div className='cookies-desc'>
				<GetTrad lang={language} value='cookiesContent' />{" "}
				<span style={{color: "#0164F8", cursor: "pointer"}} onClick={() => goTo()}>
					<GetTrad lang={language} value='cookiesReadPrivacyPolicy' />
				</span>
			</div>
			<div className='cookies-buttons'>
				<div
					className='cookies-button cookies-button-yes'
					onClick={() => {
						console.log("setCookiePopup à false");
						props.setCookiePopup(false);
					}}
				>
					<div className='cookies-buttonText'>
						<GetTrad lang={language} value='cookiesDenied' />
					</div>
				</div>
				<div
					className='cookies-button cookies-button-no'
					onClick={() => {
						setCookie("cookiesAllowed", true);
						props.setCookiePopup(false);
					}}
				>
					<div className='cookies-buttonText'>
						<GetTrad lang={language} value='cookiesAccept' />
					</div>
				</div>
			</div>
		</div>
	);
};
