// React
import React, { useEffect, useRef, useState } from "react";
// Redux
import { useSelector } from "react-redux";
//import { setCoords, setHeading } from "../../actions/map";
import { IRootState } from "../../reducers";
// Style
import "./map.css";
import "./ui.css";
// Components
import { GetTrad } from "../Partials/GetTrad";
import { POI_STATE } from "src/types/poi_state";
// Datas

import { useAuthorizations } from "src/hooks/useAuthorizations";

// Start top-left and turn in hour sens
// Latitude, Longitude
const mapLimits = [
	[50.219059, 4.947285],
	[50.218304, 4.960889],
	[50.20861, 4.96149],
	[50.209049, 4.947263],
];

// Start top-left and turn in hour sens
// y, x
const mapLimitsPercents = [
	[5.83, 6.61],
	[5.83, 94.6],
	[94.38, 94.6],
	[94.38, 6.61],
];

export const Map = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const { pois } = useSelector((state: IRootState) => state.game);

	const [scrollTarget, setScrollTarget] = useState([200, 0]);
	const _scrollTarget = useRef(scrollTarget);

	useEffect(() => {
		_scrollTarget.current = scrollTarget;
	}, [scrollTarget]);

	let newStep = useRef(0);

	// useEffect(() => {
	// 	let videoElem = document.getElementById("maskVideo") as HTMLVideoElement;

	// 	if (videoElem) {
	// 		const stopVal = (val: number) => {
	// 			switch (val) {
	// 				case 0:
	// 					return 1.8;
	// 				case 1:
	// 					return 2.1;
	// 				case 2:
	// 					return 2.8;
	// 				case 3:
	// 					return 3.2;
	// 				case 4:
	// 					return 4;
	// 				case 5:
	// 					return 5;
	// 				case 6:
	// 					return videoElem.duration;
	// 				default:
	// 					return 0;
	// 			}
	// 		};

	// 		newStep.current = stopVal(
	// 			pois.filter((p: any) => p.found).length - 1
	// 		) + 1
	// 		videoElem.currentTime = stopVal(
	// 			pois.filter((p: any) => p.found).length - 1
	// 		) + 1;
	// 	}
	// }, [pois]);

	// useEffect(() => {
	// 	let videoElem = document.getElementById("maskVideo") as HTMLVideoElement;

	// 	if (videoElem) {
	// 		const stopVal = (val: number) => {
	// 			switch (val) {
	// 				case 0:
	// 					return 1.8;
	// 				case 1:
	// 					return 2.1;
	// 				case 2:
	// 					return 2.8;
	// 				case 3:
	// 					return 3.2;
	// 				case 4:
	// 					return 4;
	// 				case 5:
	// 					return 5;
	// 				case 6:
	// 					return videoElem.duration;
	// 				default:
	// 					return 0;
	// 			}
	// 		};

	// 		newStep.current = stopVal(
	// 			pois.filter((p: any) => p.found).length - 1
	// 		) + 1
	// 		videoElem.currentTime = stopVal(
	// 			pois.filter((p: any) => p.found).length - 1
	// 		) + 1;
	// 	}
	// }, []);

	const scrollManagment = () => {
		// let mapImgContainerElem = document.getElementById("map-imgContainer");
		// let mapImgContent = document.getElementById("map-imgContent");
		// if (mapImgContainerElem && mapImgContent) {
		// 	let sizeContent = mapImgContent.getBoundingClientRect();
		// 	let sizeContainer = mapImgContainerElem.getBoundingClientRect();
		// 	let targetScroll = [
		// 		mapImgContainerElem.scrollTop,
		// 		mapImgContainerElem.scrollLeft,
		// 	];
		// 	// Vertical
		// 	// if (
		// 	// 	mapImgContainerElem.scrollTop <
		// 	// 	(mapLimitsPercents[0][0] * sizeContent.height ) / 100
		// 	// ) {
		// 	// 	targetScroll[0] = (mapLimitsPercents[0][0] * sizeContent.height) / 100;
		// 	// }
		// 	// if (
		// 	// 	mapImgContainerElem.scrollTop + sizeContainer.height >
		// 	// 	(mapLimitsPercents[2][0] * sizeContent.height) / 100 * 1.7
		// 	// ) {
		// 	// 	targetScroll[0] =
		// 	// 		(mapLimitsPercents[2][0] * sizeContent.height) / 100 * 1.7 -
		// 	// 		sizeContainer.height;
		// 	// }
		// 	// // Horizontal
		// 	// if (
		// 	// 	mapImgContainerElem.scrollLeft <
		// 	// 	(mapLimitsPercents[0][1] * sizeContent.width) / 100
		// 	// ) {
		// 	// 	targetScroll[1] = (mapLimitsPercents[0][1] * sizeContent.width) / 100;
		// 	// }
		// 	// if (
		// 	// 	mapImgContainerElem.scrollLeft + sizeContainer.width >
		// 	// 	(mapLimitsPercents[2][1] * sizeContent.width) / 100
		// 	// ) {
		// 	// 	targetScroll[1] =
		// 	// 		(mapLimitsPercents[2][1] * sizeContent.width) / 100 -
		// 	// 		sizeContainer.width;
		// 	// }
		// 	if (!_pressState.current) {
		// 		let mapImgContainerElem = document.getElementById("map-imgContainer");
		// 		if (mapImgContainerElem) {
		// 			mapImgContainerElem.scrollTo({
		// 				top: _scrollTarget.current[0],
		// 				left: _scrollTarget.current[1],
		// 				behavior: "auto",
		// 			});
		// 		}
		// 	} else {
		// 		//setScrollTarget(targetScroll);
		// 	}
		// }
	};

	let _pressState = useRef(false);
	let _mousePosPrevious = useRef([0, 0]);
	let _mousePos = useRef([0, 0]);

	useEffect(() => {
		let timeout: NodeJS.Timeout;

		let imgContentElem = document.getElementById("map-imgContent");
		let mapImgContainerElem = document.getElementById("map-imgContainer");
		let poisElems = document.getElementsByClassName(
			"map-pois-poi"
		) as HTMLCollectionOf<HTMLElement>;

		if (imgContentElem && poisElems) {
			if (props.firstMap) {
				props.setFirstMap(false);
				timeout = setTimeout(() => {
					if (imgContentElem && mapImgContainerElem) {
						//imgContentElem.style.transform = "translateY(-50%) scale(1.7)";
						// => imgContentElem.style.height = "220%"
						// for (let elem of poisElems) {
						// 	elem.style.transform =
						// 		"translateX(-50%) translateY(-50%) scale(0.6)";
						// }
					}
				}, 3000);
			} else {
				if (imgContentElem && mapImgContainerElem) {
					//imgContentElem.style.transition = "";
					//imgContentElem.style.transform = "translateY(-50%) scale(1.7)";
					// => imgContentElem.style.height = "220%"
					// for (let elem of poisElems) {
					// 	elem.style.transform =
					// 		"translateX(-50%) translateY(-50%) scale(0.6)";
					// }
				}
			}
		}

		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		let mapElem = document.getElementById("map-imgContainer") as HTMLDivElement;

		if (mapElem) {
			mapElem.scrollTop = 200;
			mapElem.scrollLeft = 0;
		}
	}, []);

	const [repere, setRepere] = useState(true);
	const [needPrevious, setNeedPrevious] = useState(false);

	/* Geoloc */
	const { askAuthorization, denyAuthorization, geoLoc } = useAuthorizations();

	const [userCoords, setUserCoords] = useState<number[]>();
	const [heading, setHeading] = useState(0);

	useEffect(() => {
		let watcher: number;
		if (geoLoc) {
			watcher = navigator.geolocation.watchPosition(
				(position) => {
					setUserCoords([position.coords.longitude, position.coords.latitude]);
					let tmp = position.coords.heading || 0;
					setHeading(tmp);
				},
				(error) => {
					console.error("Geolocation error:", error.message);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
			);
		}

		return () => {
			if (watcher) {
				navigator.geolocation.clearWatch(watcher);
			}
		};
	}, [geoLoc]);

	useEffect(() => {
		console.log("uC:", userCoords);
		console.log("heading:", heading);
	}, [userCoords]);

	useEffect(() => {
		let videoElem = document.getElementById('maskVideo') as HTMLVideoElement;

		if (videoElem) {
			const stopVal = (val: number) => {
				switch (val) {
					case 0:
						return 1.8;
					case 1:
						return 2.1;
					case 2:
						return 2.8;
					case 3:
						return 3.2;
					case 4:
						return 4;
					case 5:
						return 5;
					case 6:
						return videoElem.duration;
					default:
						return 0;
				}
			};

			videoElem.currentTime = stopVal(
				pois.filter((p: any) => p.found).length - 1
			)

			videoElem.play()
		}
	}, [pois])

	return (
		<React.Fragment>
			<div id='map' className='map'>
				{repere && !geoLoc && pois.filter((p: any) => p.found).length == 0 && (
					<div className='map-popupCenter'>
						<div className='map-popupCenter-geolocIcon'></div>
						<div className='map-popupCenter-title'>
							<GetTrad value='repereMap' lang={language} />
						</div>
						<div className='map-popupCenter-text'>
							<GetTrad value='enjoyExp' lang={language} />
						</div>
						<div
							className='map-popupCenter-button map-popupCenter-buttonBlack'
							onClick={() => {
								setRepere(false);
								askAuthorization();
							}}
						>
							<GetTrad value='letsgo' lang={language} />
						</div>
						<div
							className='map-popupCenter-button map-popupCenter-buttonGray'
							onClick={() => {
								setRepere(false);
								denyAuthorization();
							}}
						>
							<GetTrad value='notNow' lang={language} />
						</div>
					</div>
				)}
				{needPrevious && (
					<div className='map-popupCenter'>
						<div className='map-popupCenter-text'>
							<GetTrad value='dotInactiv1' lang={language} />
							<br />
							<GetTrad value='dotInactiv2' lang={language} />
						</div>
						<div
							className='map-popupCenter-button'
							onClick={() => setNeedPrevious(false)}
						>
							<GetTrad value='ok' lang={language} />
						</div>
					</div>
				)}
				<div
					id='map-imgContainer'
					className='map-imgContainer'
					onMouseMove={() => {
						if (_pressState.current) {
						}
					}}
					onTouchMove={(e: any) => {
						if (_pressState.current) {
							_mousePos.current = [e.touches[0].clientX, e.touches[0].clientY];

							let elemMapInside = document.getElementById("map-imgContent");

							if (elemMapInside) {
								// Vertical managment
								let previousTop = parseInt(
									elemMapInside.style.top.substring(
										0,
										elemMapInside.style.top.length - 2
									)
								);

								let newValueTop =
									previousTop +
									(_mousePos.current[1] - _mousePosPrevious.current[1]);

								elemMapInside.style.top = newValueTop + "px";

								let previousTopClient = elemMapInside.getClientRects()[0].top;
								let previousHeightClient =
									elemMapInside.getClientRects()[0].height;

								if (previousTopClient > 0) {
									newValueTop = previousHeightClient / 2 - 10;
								} else if (
									previousTopClient + previousHeightClient <
									window.innerHeight
								) {
									newValueTop =
										-previousHeightClient / 2 + window.innerHeight + 20;
								}

								elemMapInside.style.top = newValueTop + "px";

								// Horizontal managment
								let previousLeft = parseInt(
									elemMapInside.style.left.substring(
										0,
										elemMapInside.style.left.length - 2
									)
								);

								let newValueLeft =
									previousLeft +
									(_mousePos.current[0] - _mousePosPrevious.current[0] >= 0
										? _mousePos.current[0] - _mousePosPrevious.current[0]
										: -(_mousePosPrevious.current[0] - _mousePos.current[0]));

								elemMapInside.style.left = newValueLeft + "px";

								let previousLeftClient = elemMapInside.getClientRects()[0].left;
								let previousWidthClient =
									elemMapInside.getClientRects()[0].width;

								if (previousLeftClient > 0) {
									newValueLeft = -previousWidthClient + previousWidthClient;
								} else if (
									previousLeftClient + previousWidthClient <
									window.innerWidth
								) {
									newValueLeft = -previousWidthClient + window.innerWidth + 10;
								}

								elemMapInside.style.left = newValueLeft + "px";

								_mousePosPrevious.current = _mousePos.current;
							}
						}
					}}
					// onScroll={scrollManagment}
					onMouseDown={(e: any) => {
						_pressState.current = true;
					}}
					onTouchStart={(e: any) => {
						_pressState.current = true;
						_mousePos.current = [e.touches[0].clientX, e.touches[0].clientY];
						_mousePosPrevious.current = [
							e.touches[0].clientX,
							e.touches[0].clientY,
						];
					}}
					onMouseUp={() => {
						_pressState.current = false;
					}}
					onTouchEnd={() => {
						_pressState.current = false;
						_mousePosPrevious.current = _mousePos.current;
					}}
				>
					<div
						id='map-imgContent'
						className='map-imgContent'
						style={{
							backgroundImage: `url('/assets/map/map1.png')`,
							top: "450px",
							left: "-200px",
						}}
					>
						<video
							id='maskVideo'
							src='assets/map/map.mp4'
							style={{ height: "100%", width: "100%", display: "flex", pointerEvents: "none" }}
							muted
							autoPlay={false}
							playsInline
							onLoadStart={(e) => {
								let videoElem = document.getElementById("maskVideo") as HTMLVideoElement;

								const stopVal = (val: number) => {
									switch (val) {
										case 0:
											return 1.8;
										case 1:
											return 2.1;
										case 2:
											return 2.8;
										case 3:
											return 3.2;
										case 4:
											return 4;
										case 5:
											return 5;
										case 6:
											return videoElem.duration;
										default:
											return 0;
									}
								};

								videoElem.currentTime = stopVal(
									pois.filter((p: any) => p.found).length - 1
								)

								videoElem.play()
							}}
							onTimeUpdate={(e) => {
								let time = (e.target as any).currentTime
								let videoElem = document.getElementById("maskVideo") as HTMLVideoElement;

								if (newStep.current === time) {
									console.log("true")
								} else {
									console.log("false")
								}

								const stopVal = (val: number) => {
									switch (val) {
										case 0:
											return 1.8;
										case 1:
											return 2.1;
										case 2:
											return 2.8;
										case 3:
											return 3.2;
										case 4:
											return 4;
										case 5:
											return 5;
										case 6:
											return videoElem.duration;
										default:
											return 0;
									}
								};

								let stopValue = stopVal(pois.filter((p: any) => p.found).length);

								if (
									time >= stopValue
								) {
									videoElem.pause();
								} else {
									videoElem.play()
								}

							}}
						></video>
						{pois.map((poi: any, i: number) => {
							return (
								<div
									key={"poi_" + poi.id}
									className='map-pois-poi'
									style={{
										top:
											// mapLimitsPercents[0][0] +
											// (((poi.map.lat - mapLimitsPercents[0][0]) * 100) /
											// 	(mapLimitsPercents[3][0] - mapLimitsPercents[0][0])) *
											// 	(mapLimitsPercents[3][0] - mapLimitsPercents[0][0]),
											(((poi.map.lat - mapLimits[0][0]) * 100) /
												(mapLimits[3][0] - mapLimits[0][0])) *
											((100 -
												mapLimitsPercents[0][0] -
												(100 - mapLimitsPercents[3][0])) /
												100) +
											"%",
										left:
											mapLimits[0][1] +
											(((poi.map.lon - mapLimits[0][1]) * 100) /
												(mapLimits[1][1] - mapLimits[0][1])) *
											((100 -
												mapLimitsPercents[0][1] -
												(100 - mapLimitsPercents[1][1])) /
												100) +
											"%",
									}}
									onClick={() => {
										if (poi.clickable === "false") {
											return;
										}

										if (
											poi.parcours <= pois.filter((p: any) => p.found).length
										) {
											props.setPoi(poi.id);
										} else {
											setNeedPrevious(true);
											return;
										}

										if (!poi.found && geoLoc) {
											props.setPage("secretCode");
										} else {
											console.log("Click on already discovered")

											props.setDisplayPoiState(POI_STATE.BOTTOM);
											props.setPage("poi");
										}
									}}
								>
									<div
										className='map-pois-poiLabel'
										style={
											poi.map.labelSide === "left"
												? {
													right: "calc(0% + 24px)",
													justifyContent: "flex-end",
													transform: "translateY(-50%)",
												}
												: {
													left: "calc(0% + 24px)",
													justifyContent: "flex-start",
													transform: "translateY(-50%)",
												}
										}
									>
										<div
											className='map-pois-poiLabelText'
											style={{
												backgroundColor:
													pois.filter((p: any) => p.found).length ===
														poi.parcours
														? "black"
														: "#ede0d3",
												color:
													pois.filter((p: any) => p.found).length ===
														poi.parcours
														? "#FDFAF7"
														: "#2b1d16",
											}}
										>
											<div className=''>
												<GetTrad lang={language} value={poi.name} />
											</div>

											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='14'
												height='28'
												viewBox='0 0 14 28'
												fill='none'
												className='map-pois-poiLabelArrow'
												style={
													poi.map.labelSide === "left"
														? {
															right: "0px",
															transform: "translateX(98%)",
														}
														: {
															left: "0px",
															transform: "translateX(-98%) rotate(180deg)",
														}
												}
											>
												<path
													d='M14 14L0 0V28L14 14Z'
													fill={
														pois.filter((p: any) => p.found).length ===
															poi.parcours
															? "black"
															: "#ede0d3"
													}
												/>
											</svg>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
