export const privatePolicy = [
	{
		id: "fr",
		datas: [
			{
				type: "bold",
				value: "We want you to understand the types of information we collect as you use our services",
			},
			{
				type: "regular",
				value: "We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which %uads you’ll find most useful, the people who matter most to you online%u, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.",
			},
			{
				type: "regular",
				value: "When you’re not signed in to a Google Account, we store the information we collect with %uunique identifiers%u tied to the browser, application, or %udevice%u you’re using. This allows us to do things like maintain your preferences across browsing sessions, such as your preferred language or whether to show you more relevant search results or ads based on your activity.",
			},
			{
				type: "regular",
				value: "When you’re signed in, we also collect information that we store with your Google Account, which we treat as %upersonal information%u.",
			},
			{
				type: "bold",
				value: "Your apps, browsers & devices",
			},
			{
				type: "regular",
				value: "The information we collect includes %uunique identifiers%u, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including %uIP address%u, crash reports, system activity, and the date, time, and %ureferrer URL%u of your request.",
			},
			{
				type: "bold",
				value: "Your location information",
			},
			{
				type: "regular",
				value: "We collect location information when you use our services, which helps us offer features like driving directions, search results for things near you, and ads based on your location.",
			},

			/* */
			{
				type: "bold",
				value: "We want you to understand the types of information we collect as you use our services",
			},
			{
				type: "regular",
				value: "We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which %uads you’ll find most useful, the people who matter most to you online%u, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.",
			},
			{
				type: "regular",
				value: "When you’re not signed in to a Google Account, we store the information we collect with %uunique identifiers%u tied to the browser, application, or %udevice%u you’re using. This allows us to do things like maintain your preferences across browsing sessions, such as your preferred language or whether to show you more relevant search results or ads based on your activity.",
			},
			{
				type: "regular",
				value: "When you’re signed in, we also collect information that we store with your Google Account, which we treat as %upersonal information%u.",
			},
			{
				type: "bold",
				value: "Your apps, browsers & devices",
			},
			{
				type: "regular",
				value: "The information we collect includes %uunique identifiers%u, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including %uIP address%u, crash reports, system activity, and the date, time, and %ureferrer URL%u of your request.",
			},
			{
				type: "bold",
				value: "Your location information",
			},
			{
				type: "regular",
				value: "We collect location information when you use our services, which helps us offer features like driving directions, search results for things near you, and ads based on your location.",
			},{
				type: "bold",
				value: "We want you to understand the types of information we collect as you use our services",
			},
			{
				type: "regular",
				value: "We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which %uads you’ll find most useful, the people who matter most to you online%u, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.",
			},
			{
				type: "regular",
				value: "When you’re not signed in to a Google Account, we store the information we collect with %uunique identifiers%u tied to the browser, application, or %udevice%u you’re using. This allows us to do things like maintain your preferences across browsing sessions, such as your preferred language or whether to show you more relevant search results or ads based on your activity.",
			},
			{
				type: "regular",
				value: "When you’re signed in, we also collect information that we store with your Google Account, which we treat as %upersonal information%u.",
			},
			{
				type: "bold",
				value: "Your apps, browsers & devices",
			},
			{
				type: "regular",
				value: "The information we collect includes %uunique identifiers%u, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including %uIP address%u, crash reports, system activity, and the date, time, and %ureferrer URL%u of your request.",
			},
			{
				type: "bold",
				value: "Your location information",
			},
			{
				type: "regular",
				value: "We collect location information when you use our services, which helps us offer features like driving directions, search results for things near you, and ads based on your location.",
			},{
				type: "bold",
				value: "We want you to understand the types of information we collect as you use our services",
			},
			{
				type: "regular",
				value: "We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which %uads you’ll find most useful, the people who matter most to you online%u, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.",
			},
			{
				type: "regular",
				value: "When you’re not signed in to a Google Account, we store the information we collect with %uunique identifiers%u tied to the browser, application, or %udevice%u you’re using. This allows us to do things like maintain your preferences across browsing sessions, such as your preferred language or whether to show you more relevant search results or ads based on your activity.",
			},
			{
				type: "regular",
				value: "When you’re signed in, we also collect information that we store with your Google Account, which we treat as %upersonal information%u.",
			},
			{
				type: "bold",
				value: "Your apps, browsers & devices",
			},
			{
				type: "regular",
				value: "The information we collect includes %uunique identifiers%u, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including %uIP address%u, crash reports, system activity, and the date, time, and %ureferrer URL%u of your request.",
			},
			{
				type: "bold",
				value: "Your location information",
			},
			{
				type: "regular",
				value: "We collect location information when you use our services, which helps us offer features like driving directions, search results for things near you, and ads based on your location.",
			},
		]
	}
]