import React, { useRef } from "react";
import { trads } from "../../data/trads";

export const exponantise = (
	value: string,
	tradOrigin: string,
	i: number,
	format?: string
) => {
	if (!value || value === "") {
		return <React.Fragment></React.Fragment>;
	}

	let tradParsed = value.split("%e");

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return (
						<span key={"t-" + tradOrigin + i + "-" + index}>
							{format && format === "minus" ? t.toLowerCase() : t}
						</span>
					);
				} else {
					// Link
					return (
						<sup key={"t-" + tradOrigin + i + "-" + index}>
							{format && format === "minus" ? t.toLowerCase() : t}
						</sup>
					);
				}
			})}
		</React.Fragment>
	);
};

export const italiquize = (
	value: string,
	tradOrigin: string,
	i: number,
	format?: string
) => {
	if (!value || value === "") {
		return <React.Fragment></React.Fragment>;
	}

	let tradParsed = value.split("%i");

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return (
						<span key={"t-" + tradOrigin + i + "-" + index}>
							{exponantise(t, tradOrigin + i, index, format)}
						</span>
					);
				} else {
					// Link
					return (
						<span
							key={"t-" + tradOrigin + i + "-" + index}
							style={{ fontStyle: "italic" }}
						>
							{exponantise(t, tradOrigin + i, index, format)}
						</span>
					);
				}
			})}
		</React.Fragment>
	);
};

export const boldize = (
	value: string,
	tradOrigin: string,
	i: number,
	format?: string
) => {
	if (!value || value === "") {
		return <React.Fragment></React.Fragment>;
	}

	let tradParsed = value.split("%b");

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return (
						<span key={"t-" + tradOrigin + i + "-" + index}>
							{italiquize(t, tradOrigin + i, index, format)}
						</span>
					);
				} else {
					// Link
					return (
						<span
							key={"t-" + tradOrigin + i + "-" + index}
							style={{ fontWeight: "700" }}
						>
							{italiquize(t, tradOrigin + i, index, format)}
						</span>
					);
				}
			})}
		</React.Fragment>
	);
};

export const GetTrad = (props: any) => {
	let arr: any = [];
	if (Array.isArray(props.links)) {
		arr = props.links;
	}

	let linkCount = 0;

	let language = "fr";

	if (props.lang) {
		language = props.lang;
	}

	let tradsDatas = trads.find((t: any) => t.id === language);
	let trad = tradsDatas ? (tradsDatas as any)[props.value] : undefined;

	if (!props.value || props.value === "" || !trad) {
		return <React.Fragment></React.Fragment>;
	}

	let tradParsed = trad.split("%l");

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return (
						<span key={"t-" + props.value + "-" + index}>
							{boldize(t, props.value, index, props.format)}
						</span>
					);
				} else if (arr[linkCount]) {
					return (
						<span
							key={"t-" + props.value + "-" + index}
							className={"link"}
							style={{ fontWeight: "600", pointerEvents: "all" }}
							onClick={() => arr[Math.floor(index / 2)]()}
						>
							{boldize(t, props.value, index, props.format)}
						</span>
					);
				} else {
					// Link
					return (
						<a
							key={"t-" + props.value + "-" + index}
							href={"https://www.timescope.com/"}
							className={"link"}
							style={{ fontWeight: "600" }}
						>
							{boldize(t, props.value, index, props.format)}
						</a>
					);
				}
			})}
		</React.Fragment>
	);
};
