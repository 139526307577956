// React
import { useState } from "react";
// Utils
import { askGeoloc } from "../utils/askGeoloc";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import { setGeoLoc } from "src/actions/app";

export function useAuthorizations() {
	// Geolocation
	//const [geoLoc, setGeoLoc] = useState<boolean>(false);
	const { geoLoc } = useSelector((state: IRootState) => state.app)

	const dispatch = useDispatch();

	const askAuthorization = () => {
		return new Promise<boolean>(async (resolve, reject) => {
			let geolocTemp = geoLoc;
			try {
				if (!geoLoc) {
					let geoloc = await askGeoloc();

					if (geoloc) {
						// setGeoLoc(true);
						dispatch(setGeoLoc(true));
						geolocTemp = true;
					} else {
						// setGeoLoc(false);
						dispatch(setGeoLoc(false));
						geolocTemp = false;
					}
				}
			} catch (e: any) {
				// setGeoLoc(false);
				dispatch(setGeoLoc(false));
				geolocTemp = false;
			}
			if (geolocTemp) {
				resolve(true);
			} else {
				reject(false);
			}
		});
	};

	const denyAuthorization = () => {
		dispatch(setGeoLoc(false));
	};

	return {
		denyAuthorization: denyAuthorization,
		askAuthorization: askAuthorization,
		geoLoc: geoLoc,
	};
}
