import { useDispatch } from "react-redux";
// CSS
import "./endExp.css";
import { GetTrad } from "../Partials/GetTrad";

/* Objects to discover */
import calcStone from "../../style/assets/pois/thermalBaths/calcStone.png";
import monay from "../../style/assets/pois/hauterecenne/monay.png";
import reindeer from "../../style/assets/pois/nutons/reindeer.png";
import bone from "../../style/assets/pois/frontal/bone.png";
import sediment from "../../style/assets/pois/goldenPaddle/sediment.png";
import silex from "../../style/assets/pois/reuviau/silex.png";

export const EndExpObj = (props: any) => {
    return <div className="endExp-obj" style={{
        position: "absolute",
        height: "33%", width: "46%",
        left: "50%",
        top: '50%',
        transformOrigin: "50% 50%",
        transform: "translateX(" + props.left + ") translateY(" + props.top + ") rotate(" + (Math.random() * 6 - 3) + "deg)",
        backgroundColor: "#FBF3EB",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <div className='secretCode-congrats-imgContainer' style={{height: "50%"}}>
            <div
                className='secretCode-congrats-img'
                style={{ backgroundImage: props.img, height: "60%" }}
            ></div>
        </div>
        <div
                className='secretCode-congrats-desc'
                style={{
                    width: "90%",
                    textTransform: "uppercase",
                    textAlign: "center",
                    margin: "0 0 8px 0",
                    color: "#2B1D16",
                    fontFamily: "Ortica Linear",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                }}
            >
                <GetTrad lang='fr' value={props.title} />
            </div>
    </div>;
}

export const EndExp = (props: any) => {
    const dispatch = useDispatch();

    return <div className='endExp'>
        <div className='endExp-congratsContainer'>
            <div className='endExp-congratsWhite'></div>
            <div className='endExp-congrats'>
                <div className='endExp-congrats-objectName'>
                    <GetTrad lang='fr' value={"welldone"} />
                </div>
                <div className='endExp-congrats-found'>
                    <GetTrad lang='fr' value={"allfound"} /><br />
                    <GetTrad lang='fr' value={"allfound2"} />
                </div>
                {/* <div className='endExp-congrats-objectName'>
                    <GetTrad lang='fr' value={poi?.riddle.title} />
                </div> */}
                <div className='endExp-congrats-core'>
                    <div className='endExp-container'>
                        <EndExpObj title={"thermalBathsRiddleTitle"} img={"url(" + calcStone + ")"} left={"-99%"} top={"-152%"} />
                        <EndExpObj title={"hauterecenneRiddleTitle"} img={"url(" + monay + ")"} left={"-1%"} top={"-152%"} />
                        <EndExpObj title={"nutonsRiddleTitle"} img={"url(" + reindeer + ")"} left={"-99%"} top={"-50%"} />
                        <EndExpObj title={"frontalRiddleTitle"} img={"url(" + bone + ")"} left={"-1%"} top={"-50%"} />
                        <EndExpObj title={"goldenPaddleRiddleTitle"} img={"url(" + sediment + ")"} left={"-99%"} top={"52%"} />
                        <EndExpObj title={"reuviauRiddleTitle"} img={"url(" + silex + ")"} left={"-1%"} top={"52%"} />
                    </div>
                    <div
                        className='endExp-shareButton'
                        onClick={() => {
                            props.setSharePopup("true")
                        }}
                    >
                        <GetTrad lang='fr' value='shareExp' />
                        <div className='endExp-buttonShare'></div>
                    </div>
                    <div
                        className='endExp-continueButton'
                        onClick={() => {
                            props.setFirstEnd(false)
                        }}
                    >
                        <GetTrad lang='fr' value='backMap' />
                    </div>
                </div>
            </div>
        </div>
    </div>

}