import flagFr from "../style/assets/splashscreen/flags/fr.png";
import flagEn from "../style/assets/splashscreen/flags/en.png";
import flagDe from "../style/assets/splashscreen/flags/de.png";

export const trads = [
	{
		id: "fr",
		flag: `url(${flagFr})`,
		lang: "Français",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "Sur les traces de l'Homme",
		start: "Démarrer",
		/* Menu */
		timeline: "Frise",
		map: "Carte",
		glossary: "Lexique",
		/* Map */
		repereMap: "Repérez-vous sur la carte",
		enjoyExp:
			"Pour profiter à fond de l'expérience Furfooz, nous vous conseillons de partager votre localisation",
		letsgo: "C'est parti !",
		notNow: "Je ne suis pas sur place",
		ok: "Ok",
		dotInactiv1: "Ce point n'est pas encore actif !",
		dotInactiv2: "Vous devez d'abord déverouiller les points précédents.",
		restartExp: "Recommencer l'expérience ?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Un jeu immersif pour découvrir les trésors de Furfooz sur les traces d’Edmond, le célèbre archéologue belge.",
		manyMarquage:
			"Rendez-vous sur les différents marqueurs du parcours et trouvez sur place le code secret qui révélera des contenus exclusif et de nombreuses surprises !",
		playingInPlace: "Jouer en mode \"à distance\"",
		cutGeoloc: "Couper la géolocalisation et accéder aux contenus sans répondre aux énigmes",
		resetExp: "Réinitialiser l'expérience",
		credits: "Crédits",
		timescopeProject: "Un projet Timescope",
		creditsContent: "%bContenus%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotographie%b Photographe",
		creditsTexts: "%bTextes %bOona Farell",
		creditsDesign: "%bDesign de l'application mobile%b Felix Marquette",
		/* SecretCode */
		validate: "Valider",
		validate2: "Ré-essayer",
		badanswer: "Ce n'est pas ça...",
		bravo: "Bravo !",
		continue: "Continuer",
		slideToDiscover: "Cliquer pour continuer",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Légende de l'image",
		backMap: "Retour à la carte",
		/* Timeline */
		timelineTitle: "Frise Chronologique",
		scrollArrowsExpl:
			"Pssst... Si vous êtes fatigués de scroller, utilisez les flèches pour vous déplacer plus rapidement d'une période à l'autre !",
		explanationTimeline:
			"L'époque moderne ne représente qu'une petite fraction de l'histoire de l'humanité. Serez-vous capable de remontrer jusqu'aux traces des premiers humains à Furfooz dans cette frise où chaque pixel correspond à 1 an ?",
		timelineBottom: "Vous êtes arrivés au bout ! Bravo :)",
		timelineBackFutur: "Retour vers le futur",
		/* Glossary */
		explanationGlossaryLine1:
			"Voici les termes techniques que vous avez pu rencontrer jusqu'à présent.",
		explanationGlossaryLine2:
			"Découvrez d'autres points d'intérêts pour remplir votre lexique !",
		unlockTo: "Débloqué aux ",
		glossaryUnlock: "Nouveau terme ajouté au lexique !",
		goGlossary: "Voir le lexique",
		/* Cookies */
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour sauvegardez vos progrès dans le jeu. En poursuivant l’expérience vous acceptez notre usage de cookies.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire notre politique de confidentialité.",
		/* PrivatePolicy */
		privatePolicyTitle: "Politique de confidentialité",
		/* EndExp */
		welldone: "Beau travail !",
		allfound: "Vous avez trouvé tous les trésors de Furfooz.",
		allfound2: "Edouard Dupont serait fier de vous.",
		shareExp: "Partagez votre expérience",
		shareExpWithFriends: "Partagez votre expérience avec vos amis !",
		link: "Lien",
		x: "X",
		whatsapp:"Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Vous avez résolu la première énigme !",
		thermalBathsRiddleTitle: "Pierre calcaire",
		thermalBathsRiddleDesc:
			"Fantastique ! En effet, les thermes sont bâtis à l’aide de pierres calcaires. Pour lier l’ouvrage, les Romains ont utilisé du mortier de teinte grisâtre compact et du fin gravier de rivière. Et puis, le plateau de Hauterecenne est fait de pierres calcaires, il n’y a plus qu’à se servir.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Félicitations pour cette deuxième énigme !",
		hauterecenneRiddleTitle: "Pièce frappée",
		hauterecenneRiddleDesc:
			"Bien joué ! Sur le plateau de Hauterecenne, les archéologues ont découvert des pièces de monnaie en grande quantité… et traversant bien des siècles !",

		/* Nutons */
		nutonsRiddleDisc: "Encore une énigme réussie !",
		nutonsRiddleTitle: "Bois de renne gravé",
		nutonsRiddleDesc:
			"Bravo ! Au Trou des Nutons, on a découvert un bois de renne sur lequel est gravé une tête de bison, et lorsqu’on le retourne, on y voit un mammouth !",

		/* Frontal */
		frontalRiddleDisc: "Vous avez résolu la quatrième énigme !",
		frontalRiddleTitle: "Os frontal d’un adolescent",
		frontalRiddleDesc:
			"Félicitation ! Edouard Dupont a découvert en 1864 un os frontal appartenant à un adolescent ainsi que deux crânes humains intacts conservés dans un ossuaire.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Encore une énigme, continuez ainsi !",
		goldenPaddleRiddleTitle: "Bout d’une roche avec des sédiments",
		goldenPaddleRiddleDesc:
			"Bravo ! Ce bout de roche est une source d’information précieuse pour les paléontologues. À l’intérieur, on y voit des sédiments, un dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide. Grâce à eux, nous pouvons dater le passage de l’Homme dans la grotte.",

		/* Reuviau */
		reuviauRiddleDisc: "C'était la dernière énigme !",
		reuviauRiddleTitle: "Silex taillé magdalénien",
		reuviauRiddleDesc:
			"Fantastique ! Vous êtes arrivés à la fin de votre parcours. Les silex taillés sont les outils emblématiques de la Préhistoire. Les cavités de Furfooz ont livré de belles séries de silex taillés magdaléniens et du Néolithique moyen.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen-Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen-Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration: "Période qui s’étend de l’invention de l’écriture au début du Moyen-Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl: "Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration: "Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Accueil",

		/* thermalBaths */
		thermalBaths: "Thermes gallo-romains",
		thermalBathsTitle: "Thermes gallo-romains",
		thermalBathsSubtitle:
			"Les thermes, un lieu incontournable de la vie à l’époque romaine/dans le quotidien des romains !",
		thermalBathsSec1Title: "Furfooz romain",
		thermalBathsSec1Content:
			"En toute terre où les romains s’installent, il est un lieu incontournable : les thermes. Furfooz ne déroge pas à la règle ! Et oui, au Bas-Empire, après la conquête romaine de l’Europe, le plateau de Hauterecenne devient un fortin très important occupé par des auxiliaires barbares au service de Rome. Il faut donc y construire des infrastructures…",
		thermalBathsSec2Title: "Comment ça fonctionne, des thermes ?",
		thermalBathsSec2Content:
			"D’une superficie modeste de 75m2, le bâtiment comprend tous les éléments traditionnels de ces établissements romains, à savoir : une salle au bain chaud (caldarium) et au bain tiède (tepidarium), ainsi qu’une salle au bain froid (frigidarium). La pièce principale est chauffée par %lhypocauste%l, tandis que le bain froid est situé à l’écart, en saillie sur une petite pièce quadrangulaire.",
		thermalBathsEncart1Title: "Pourquoi donc un établissement thermal ici ?",
		thermalBathsEncart1Content:
			"Il plane bien des mystères autour de la présence de thermes sur le site de Furfooz : De quelle période sont-ils datés? Comment les romains ont-ils réussi à acheminer l’eau jusqu’à l’édifice? Comment expliquer l’isolement total en cet endroit de cet ensemble thermal du Haut-Empire ? Antérieur au IVe siècle, certains auteurs justifient son existence par la présence possible d’une villa Gallo-romaine à proximité, mais aucune %lprospection%l n’a jamais pu en localiser l’emplacement. En ce qui concerne l’acheminement de l’eau, les hypothèses archéologiques vont bon train. En l’absence de sources sur le plateau, le géologue Van de Poel envisage par exemple l’existence d’une conduite d’eau qui aurait alimenté les thermes au départ d’une source située à quelques 600m delà, au Fond-des-Vaux. Les %larchéologues%l ont encore de nombreuses questions à élucider !",
		thermalBathsSec3Title: "Plus qu’un bain, un lieu social et civique !",
		thermalBathsSec3Content:
			"Si les thermes ont pour fonction première d’assurer l’hygiène de la cité, ces bains publics sont aussi un haut lieu de sociabilité. Dans la société romaine, on y va tous les jours pour y rencontrer ses amis ou encore y faire du sport, et ce qu’importe son statut social ! Ils sont ouverts à tous, sans distinction de classe sociale, aux hommes comme aux femmes (dans des parties ou à des heures différentes).",
		thermalBathsEncart2Title:
			"L’archéologie expérimentale, une aventure scientifique !",
		thermalBathsEncart2Content:
			"Ils sont passionnés ces archéologues ! D’abord exploré en 1876-1877 par Alfred Bequet de la Société archéologique de Namur, il faut attendre le XXe siècle, de 1956 à 1958, pour qu’on entreprenne une reconstruction fidèle et grandeur nature des thermes romains. Grande aventure scientifique, l’archéologie expérimentale permet de reconstituer ces édifices disparus, de la façon la plus rigoureuse possible, et ainsi rendre accessible la pratique et l’Histoire à un large public. Un bel exemple de pédagogie !",
		thermalBathsSec4Title:
			"Quand l’horizon du Moyen- ge sonne le glas des thermes gallo-romains",
		thermalBathsSec4Content:
			"Si les thermes sont parfaitement visibles grâce à leurs reconstructions, l’histoire du site est bien plus complexe. En effet, à l’orée du Moyen- ge, le plateau de Hauterecenne est progressivement abandonné par le camp romain. Vers le dernier tiers du IVe siècle, le site des thermes va alors prendre une nouvelle fonction, celle d’une petite %lnécropole%l, une fois le bâtiment détruit.",
		thermalBathsEncart3Title:
			"Bâtir une nécropole sur les ruines de thermes gallo-romain",
		thermalBathsEncart3Content:
			"Pour enterrer ses morts, la communauté occupant le site fortifié à la fin du IVe siècle jette son dévolu sur l’espace thermal, devenu terrain vague. La surface nécessaire pour y enfouir les défunts n’est pas très grande puisqu’on compte environ vingt-cinq sépultures fouillées. Les tombes sont creusées à même le sol, sans fondation ou infrastructure. L’étude de cette nécropole tardive dit beaucoup de choses de ses habitants, notamment la découverte de nombreux objets. Car oui, les haches laissent penser que la population de Furfooz seraient de nouveaux arrivants barbares, mais les beaux ceinturons enterrés appartiennent plus à la culture militaire romaine tardive. D’autres trouvailles compliquent l’identification certaine de ce peuple. Quoi qu’il en soit, c’est un beau mélange de culture à Furfooz !",
		thermalBathsSec5Title:
			"Actualité de l’archéologie, le site à nouveau fouillé !",
		thermalBathsSec5Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Hauterecenne */
		hauterecenne: "Plateau fortifié de Hauterecenne",
		hauterecenneTitle: "Plateau fortifié de Hauterecenne",
		hauterecenneSubtitle: "Une forteresse, plusieurs vies !",
		hauterecenneQuote1:
			"“L’homme, à toutes les époques, s’est établi dans cette localité [Furfooz] suivant les diverses conditions de son temps.”",
		hauterecenneQuoteAuthor1:
			"Edouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Les traces de fortifications scrutées à la loupe tardivement",
		hauterecenneSec1Content:
			"Le champ de recherche préféré à Furfooz, c’est la paléontologie. Et pourtant, il existe d’autres traces de la présence humaine bien plus tardives et qui méritent une pleine attention ! Le plateau de Hauterecenne, au cœur de cette activité humaine du %lBas-Empire%l et du Haut Moyen Âge, est fouillé marginalement au XIXe siècle, mais il faut attendre les années 1930, puis 1980 pour qu’il fasse l’objet d’études approfondies.\nEn route pour découvrir tous ses secrets !",
		hauterecenneSec2Title:
			"Hauterecenne, un plateau stratégique pour l’installation… d’une garnison romaine !",
		hauterecenneSec2Content:
			"En étudiant les traces des anciens remparts, les archéologues ont mis au jour la présence d’une garnison romaine du Bas-Empire sur le site de Furfooz. Mais pourquoi s’installer ici ? Pour sa situation stratégique bien-sûr ! Le plateau de Hauterecenne est un haut promontoire dominant la rivière, qui dispose d’une protection naturelle de ce côté mais aussi sur son flanc où le Ry des Vaux a lui-même creusé une petite vallée escarpée. Ainsi, situé au-dessus d’une falaise abrupte surplombant la Lesse de 60 mètres, il est très facile de fortifier l site et de se parer contre toute attaque ennemie ! Le plateau porte encore les traces bien conservées d’un certain nombre de structures anciennes et d’éléments de fortifications de périodes différentes. Les archéologues vous racontent.",
		hauterecenneEncart1Title:
			"Les archéologues étudient ces lieux stratégiques",
		hauterecenneEncart1Content:
			"« Depuis le milieu du XIXème siècle, on y a conduit plusieurs campagnes de fouilles et le grand mérite de Raymond Brulet est d’avoir repris l’étude du site [...]. Le site a été ainsi occupé depuis l’époque romaine jusqu’au XIIIème siècle avec des temps forts au milieu du IIIème siècle et au début du Vème siècle. » Raymond Brulet, La fortification de Hauterecenne à Furfooz, (publication d'histoire de l'Art et d'Archéologie de l'Université catholique de Louvain XIII), 1978",
		hauterecenneSec3Title: "Puis d’une petite forteresse médiévale !",
		hauterecenneSec3Content:
			"Raymond Brulet nous met sur la piste… La forteresse aurait donc évolué au fil du temps ? Et oui, après la chute de l’Empire romain et le départ de ses troupes, le site se fortifie épisodiquement. Nous n’avons que peu d’informations quant aux habitants qui s’y installèrent, en revanche la présence d’une maison forte et l’ajout de remparts suggèrent l’installation d’un petit seigneur régional. À la fin du XIIIème siècle, le site perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		hauterecenneEncart2Title: "Savez-vous ce qu’est une maison forte ?",
		hauterecenneEncart2Content:
			"La maison forte est l’ancêtre des châteaux forts. De taille modeste, il n’est possible d’y pénétrer que par le 1er étage. Ainsi, si la maison est attaquée, l’escalier peut être retiré et les occupants protégés !",
		hauterecenneSec4Title:
			"Une superposition d’époques, comment faire pour les distinguer ?",
		hauterecenneSec4Content:
			"Pour retracer l’histoire et l’évolution de l’occupation humaine sur un site, l’archéologue outillé, mais s’entoure de spécialistes. Dans le cas du plateau fortifié, il faut pouvoir distinguer les époques, alors qu’elles se superposent. On travaille alors sur une chronologie relative et on fait appel à des comparaisons site par site. Ce long cheminement a permis de faire une découverte importante : la présence de deux murs de barrage.",
		hauterecenneSec5Title: "L’affaire des deux murs de barrage",
		hauterecenneSec5Content:
			"Et oui, les différentes époques sont visibles sur les murs ! Le premier mur de barrage, le plus extérieur, date du début du Bas-Empire romain. Très étroit et construit selon la même technique que les thermes, cela laisse à penser qu’il devait être muni soit d’un talus de terre appuyé contre lui, soit d’un parapet en bois, ancré dans le mur. Pour une raison que l’on ignore, un second mur de barrage a été élevé ensuite. Il bénéficie d’une large porte charretière et son épaisseur est cette fois plus commune. Il remonte sans doute au Ve siècle. Lors de la dernière mise en défense du site, à l’époque du Moyen- ge classique, ce mur a été bouché, puis surélevé. Son système de circulation au sommet de l’infrastructure repose sur un série de piliers à arcatures.",
		hauterecenneSec6Title: "Et si on fouillait encore ce lieu ?",
		hauterecenneSec6Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsSubtitle: "Entre découvertes paléontologiques et légende populaire",
		nutonsSec1Title: "Le Trou des nutons, première grotte explorée",
		nutonsSec1Content:
			"En 1864, notre cher Edouard Dupont se lance en premier dans la fouille du trou des Nutons. S’il le choisit comme point de départ de son exploration, c’est parce qu’il présente toutes les caractéristiques idéales à l’implantation d’un campement magdalénien : bien abrité, logé à 40 m au-dessus du lit de la rivière donc proche d’une source d’eau… parfait pour cette population semi-nomade de chasseurs de rennes ou de chevaux !",
		nutonsSec2Title: "Quand l’étude des sols rencontre l’étude des Hommes",
		nutonsSec2Content:
			"Mais comment diable Edouard Dupont tire-t-il autant de conclusions au sujet d’une population si lointaine ? En effectuant ce l’on appelle en archéologie un sondage en archéologie. Sur une toute petite partie de la grotte, l'équipe de Dupont retire les couches successives du sol, jusqu’à 8 m de profondeur pour les étudier. Ces couches sont composées de sédiments, c’est-à-dire de dépôts organiques qui en disent beaucoup sur la période d’occupation et les modes de vie de nos ancêtres. Indéniablement, géologie et archéologie sont intimement liées.",
		nutonsSec3Title: "Des premières grandes découvertes",
		nutonsSec3Content:
			"Edouard Dupont ne s’arrête pas aux sondages ! Il fait dynamiter les stalagmites et les blocs de pierre. Sous ceux-ci, il fait de grandes découvertes : des débris  d’une vingtaine d’espèces animales, des couteaux et des flèches en silex, des pièces de monnaie de l’époque gallo-romaine et divers ustensiles postérieurs. Par contre, il n’y a pas la moindre trace d’ossements humains… Pourtant, tout portait à croire que la découverte de crânes était possible !",
		nutonsSec4Title: "Le %lpaléontologue%l écoute les légendes populaires",
		nutonsSec4Content:
			"Et oui ! Si Edouard Dupont fait le choix de ce trou pour commencer son exploration, c’est aussi car une légende lui est associée… Selon la croyance populaire, les Nutons étaient des nains à l’aspect vieux et chétif, mais actifs, très malicieux et fort habiles de leurs mains. Ils vivaient cachés dans les rochers, les cavernes et les ruines. Pendant la nuit, pour un peu de nourriture et quelques friandises déposées à l’entrée de leurs  gîtes, ces êtres surnaturels réparaient les ustensiles, les objets mobiliers, raccommodaient des haillons et blanchissaient le linge des villageoises. À la belle saison, ils sortaient en nombre pour se livrer à de joyeuses danses dans les prés. Taquins, ils pouvaient être très susceptibles et se vengeaient de ceux qui les bernaient ou cherchaient à découvrir leurs secrets.",
		nutonsEncart1Title: "Une explication scientifique de la légende ?",
		nutonsEncart1Content:
			"Edouard Dupont tente d’expliquer la petite taille des populations de Furfooz : « Les hommes de Grenelle, et surtout ceux de Furfooz, étaient de petite taille. Les premiers alignaient encore une moyenne d’1m62, mais les seconds descendaient à 1m53. C’est presque exactement la taille moyenne des Lapons. Toutefois, cette stature réduite n’excluait ni la vigueur ni l’agilité nécessaire aux populations sauvages. Les os des membres et du tronc sont robustes, et les saillies, les dépressions de leur surface, accusent un développement musculaire très prononcé. À part cette robusticité générale, supérieure à ce que l’on rencontre habituellement, les squelettes des hommes de Furfooz et de Grenelle ressemblent fort à celui des hommes d’aujourd’hui » (Edouard Dupont, « Sur les crânes de Furfooz », Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"Aussi mystérieux qu’emblématique, le Trou des Nutons a toujours de quoi vous surprendre ! Continuez votre exploration de la réserve pour découvrir bien d’autres secrets.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalSubtitle: "Entre habitat et sépulture",
		frontalSec1Title:
			"S’il est appelé Trou du Frontal, c’est grâce à une découverte !",
		frontalSec1Subtitle:
			"À votre avis, pourquoi ce nom ? Tout simplement car notre ami Edouard Dupont, en fouillant le trou en 1864, met la main dès les premiers coups de pioche sur un os frontal appartenant à un adolescent. Cette grande découverte est un événement dans le monde de la recherche et est annonciatrice de bien d’autres…",
		frontalSec2Title:
			"la découverte de deux crânes très différents, une énigme à élucider",
		frontalSec2Subtitle:
			"Le 10 décembre de la même année, le paléontologue dégage, en présence d’invités de marque, deux crânes humains intacts conservés dans un ossuaire. Cela ouvre un champ d’hypothèses immense quant à la période d’occupation de ces grottes, mais aussi aux modes de vie de nos ancêtres.",
		frontalEncart1Title: "Récit de fouilles par Dupont",
		frontalEncart1Content:
			"“Cette petite caverne, tant par ses données géologiques que par les lumières qu’elle a répandues sur l’ethnographie ancienne, est la plus importante qui ait encore été fouillée dans notre pays. Il est certain que si l’explorateur eût souvent vu ses recherches couronnées d’un succès aussi complet, les laborieux travaux, entrepris dans notre région, eussent été fort abrégés, et il resterait aujourd’hui bien peu de chose à dévoiler sur les habitants de la Belgique à l’époque quaternaire. [...] Nous examinerons plus loin les données que les restes des repas dans l’abri extérieur et l’amas d’ossements humains nous fournissent sur les coutumes funéraires de l’antique peuplade.” \n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Se tromper fait partie de la recherche paléontologique !",
		frontalSec3Subtitle:
			"En étudiant ces ossements, Dupont les date à tort de l’époque des chasseurs de rennes. Mais être paléontologue c’est aussi se tromper ! Des études plus approfondies sur ces crânes et de l’ensemble des matériaux découverts sur le site ont mis au jour une confusion de la part de Dupont entre des traces de Magdaléniens, les fameux chasseurs, et celles de populations vivant au Néolithique.",
		frontalEncart2Title:
			" Quand la théorie de Darwin éclaire le monde paléontologique",
		frontalEncart2Content:
			"Les fouilles entreprises par Dupont sont considérées comme le premier argument anatomique en faveur des théories de  Darwin, retranscrites dans son ouvrage L’origine des espèces publié en 1859, quelques années avant ses découvertes. Grâce à ces crânes, il comprend l’évolution de l’espèce humaine en fonction des périodes d’occupation. Car oui, des Hommes de deux ères vivaient au trou du Frontal !",
		frontalSec4Title: "Des Hommes de deux ères : la vie, la mort",
		frontalSec4Subtitle:
			"Au Trou du Frontal, les fouilles archéologiques ont mis au jour à la fois des silex taillés datant de l’époque %lMagdalénienne%l, mais aussi de nombreux ossements, d’au moins 16 individus (hommes, femmes et enfants) situés nettement au-dessus de la couche du niveau magdalénien. Tout porte à croire qu’il s’agit-là de vestiges d’une sépulture datant du Néolithique moyen. Près de 10 000 ans les séparent et pourtant, tous ont choisi le trou du Frontal pour refuge !",
		frontalSec5Title: "Vivre et mourir au Trou du Frontal",
		frontalSec5Subtitle:
			"L’amas d’ossements suggère un espace funéraire plutôt qu’un habitat. En effet, le procédé d’inhumation, c’est-à-dire d’enterrer ses morts s’étend à tout le territoire actuel de la Belgique pendant le Néolithique, il y a 7000 ans. Le Trou du Frontal en est un parfait exemple puisqu’il est même fermé par une dalle, comme on le ferait pour une tombe, ce qui confirme qu’il s’agit-là d’une sépulture secondaire datant du Néolithique moyen.",
		frontalSec6Title:
			"Du trou du Frontal au trou de la Mâchoire… il n’y a que 10 mètres à parcourir !",
		frontalSec6Subtitle:
			"La pratique funéraire est très présente sur le site de Furfooz. À quelques pas de trou du Frontal, le trou de la Mâchoire avait sans doute la même fonction puisqu’on y a découvert cinq squelettes dans un état remarquable. Et, nous le savons, étudier les morts c’est comprendre la vie !\nPassez à la buvette située non loin du trou du Frontal pour profiter pleinement des beautés de la nature.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleSubtitle:
			"Une mine d’informations sur l’histoire de l’humanité !",
		goldenPaddleSec1Title:
			"Pourquoi diable étudier la roche pour en apprendre sur les Hommes ?",
		goldenPaddleSec1Subtitle:
			"“Les études de cette nature, qui puisent leurs éléments dans les sciences géologiques, sont donc du ressort exclusif des sciences naturelles. Par ces investigations, la géologie donne la main à l’histoire et l’aide à restaurer le passé le l’humanité.” Edouard Dupont, Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse,1872\n\nCes quelques mots de Edouard Dupont transmettent bien l’importance de l’étude de la roche, la sédimentation, dans la compréhension de l’histoire de l’humanité. On vous explique.",
		goldenPaddleSec2Title:
			"La sédimentation, une mine d’or pour les scientifiques !",
		goldenPaddleSec2Subtitle:
			"En décryptant les modifications du milieu naturel, celui que l’on appelle le géomorphologue nous renseigne sur les activités humaines qui l’ont façonné. En étudiant les sédiments, il détermine l’origine des dépôts (alluvions, ruissellements, remblais…) ou leur âge. Il observe aussi la succession des strates de sédiments visible grâce aux changements de couleur ou de texture des sols (argiles, sables, cailloux…). Chaque strate correspondant à une époque, la méthode stratigraphique permet de déterminer la nature et la chronologie des événements intervenus. À la Gatte d’Or, les couches sédimentaires sont très visibles et renseignent aussi bien sur la présence humaine que sur les origines même de Furfooz.",
		goldenPaddleSec3Title:
			"Des fouilles qui détériorent le patrimoine, cela arrive aussi…",
		goldenPaddleSec3Subtitle:
			"Au début du XXe siècle, le site de Furfooz n’est plus habité depuis longtemps mais est un haut lieu touristique payant. Hélas ! Après les nombreuses fouilles de Dupont, rien n’a été prévu pour protéger l’intérieur des grottes. L’afflux des vacanciers n’a fait qu’empirer les choses avec une détérioration à l’intérieur des  grottes dont celle de la Gatte d’or : bris de concrétions calcaires et altération de la teinte des  draperies suite à un mauvais emploi des bougies utilisées pour l’éclairage.",
		goldenPaddleEncart1Title: "La légende raconte…",
		goldenPaddleEncart1Content:
			"La légende du pays raconte qu’une gatte, une chèvre en wallon, vivrait dans les grottes de Furfooz et garderait un trésor… Cette histoire populaire remonte à une lointaine époque, où le tissu manquait et les banques n’existaient pas. Les gens confectionnaient de petits sacs en peau poilue et cachaient leurs deniers où ils pouvaient. Si bien que parfois, ils les oubliaient ou mourraient avec leur secret… Les malheureux sans mémoire font des heureux bien plus tard ! De cette légende naît la fameuse gatte toute d’or vêtue !",
		goldenPaddleSec4Title:
			"Aujourd’hui, la Gatte d’or est un spot naturel incontournable !",
		goldenPaddleSec4Subtitle:
			"Heureusement, l’association Ardenne & Gaume œuvre aujourd’hui pour la préservation de ce patrimoine. La présence humaine se fait quasi invisible et laisse place à un paysage naturel exceptionnel. La vue y est à couper le souffle !",

		/* Reuviau */
		reuviau: "Trou Reuviau",
		reuviauTitle: "Trou Reuviau",
		reuviauSubtitle: "Au Trou Reuviau, on se met à table !",
		reuviauSec1Title: "Reuviau, un trou discret mais riche en savoir",
		reuviauSec1Subtitle:
			"À la fin de votre promenade dans la réserve naturelle de Furfooz, n’oubliez pas de vous arrêter au trou Reuviau !  Modeste, il est pourtant très intéressant pour les paléontologues, notamment pour connaître mieux les pratiques alimentaires des Magdaléniens. On vous explique.",
		reuviauEncart1Title: "Fouilles du Reuviau par E.Dupont",
		reuviauEncart1Content:
			"“Une autre petite caverne, le trou Reuviau, dans un ravin sur le chemin qui conduit de la Lesse à Furfooz, a fourni, dans les mêmes circonstances géologiques, des ossements de cheval, de renne, d’ours, etc., brisés de main d'homme.”\n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "Le repas des Hommes à l’âge du renne",
		reuviauSec2Subtitle:
			"Edouard Dupont, puis bien d’autres paléontologues, ont découvert une série d’ossement de rennes et de chevaux qui portent à croire qu’ils étaient des mets de choix pour ces hommes des cavernes… cela a même donné le nom à l’âge du renne, pour dire ! Des silex mis au jour dans le trou en disent beaucoup sur la façon dont ces hommes dépeçaient les bêtes, aussi bien pour se nourrir que pour se vêtir.",
		reuviauEncart2Title: "Dupont défini l’âge de pierre",
		reuviauEncart2Content:
			"“La chasse leur fournissait leurs aliments et leurs grossiers vêtements ; l’os et surtout la pierre-à-feu leur donnaient des outils et des armes, sans qu’elles eussent pu arriver à produire des métaux ou seulement à en soupçonner l’existence. Aussi a-t-on désigné, sous le nom d’âges de pierre, ces époques lointaines et de longue durée.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "La chasse à la Préhistoire",
		reuviauSec3Subtitle:
			"Durant le paléolithique, le renne, passant en troupeaux dans les vallées, est le gibier de prédilection des hommes préhistoriques. De nombreuses traces de ces chasses ont été retrouvées dans les diverses cavernes de Furfooz. Animal grégaire et migrateur, le renne était sans doute aussi un animal facile à chasser, et à chasser en masse… Mais aussi à faire sécher ! L'ensemble de ces facteurs, étudiés par les paléontologues, nous en apprennent toujours un peu plus sur nos ancêtres !",
		reuviauSec4Title: "L’enquête dans les trous de Furfooz continue !",
		reuviauSec4Subtitle:
			"Vous arrivez à la fin de votre exploration mais, n’en doutez pas, vous n’avez découvert qu’une infime partie de la réserve naturelle de Furfooz ! N’hésitez pas à revenir pour en explorer ses moindres recoins, sa faune et sa flore luxuriantes, protégées depuis près de 70 ans !\nÀ bientôt.",

		furfoozMystery: "Les mystères de Furfooz",
		archaeology: "Archéologie",
		doYouKnow: "Bon à savoir",
		anecdote: "Anecdote",
		edouardDupont: "L'anecdote Dupont",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paléontologue",
		palaeontologistDesc:
			"celui qui étudie l'apparition, l'évolution et l'extinction des espèces animales, au long d'une immense période qui remonte aux premières traces de vie sur Terre, il y a environ 3,5 milliards d'années.",
		archaeologist: "Archéologue",
		archaeologistDesc:
			"celui qui étudie les civilisations humaines du passé à partir de traces matérielles qui en subsistent (bâtiments, œuvres d’art, pièces de monnaie, tombes, manuscrits, objets de la vie quotidienne…).",
		geologist: "Géologue",
		geologistDesc:
			"celui qui étudie la composition et la structure des sols et roches de la croûte terrestre. Le but? Expliquer l’évolution de notre planète.",
		anthropologist: "Anthropologue",
		anthropologistDesc:
			"celui qui étudie l’homme, son comportement et son évolution. Sur les chantiers archéologiques, il analyse les squelettes afin de déterminer les conditions de vie et de mort de la personne. Il décrit également les sépultures (type de tombe, parures, offrandes, mobilier) afin de pouvoir reconstituer les gestes funéraires effectués par les proches du défunt.",
		sediment: "Sédiment",
		sedimentDesc:
			"dépôt qui se forme à partir de l’altération ou de la désagrégation de roches anciennes.",
		experimentalArchaeology: "Archéologie expérimentale",
		experimentalArchaeologyDesc:
			"Discipline de l’archéologie, cette discipline consiste à restituer, grâce à l’expérimentation, des bâtiments ou objets selon les techniques de construction et de fabrication d’époque.",
		necropolis: "Nécropole",
		necropolisDesc:
			"Equivalent d’un cimetière à l’Antiquité, révélé par l’archéologie. Les sépultures peuvent être situées dans des excavations souterraines ou être creusées dans le sol.",
		hypocaust: "Hypocauste",
		hypocaustDesc:
			"Local souterrain renfermant un système destiné à chauffer les bains, les salles des thermes ou encore des pièces à vivre.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"Ensemble des théories biologiques de Darwin et de ses disciples selon lesquels la sélection naturelle est le facteur essentiel de la transformation des espèces vivantes.",
		prospecting: "Prospection (archéologie)",
		prospectingDesc:
			"La prospection consiste à observer et étudier un site archéologique sans creuser dans le sol. Elle peut se faire à pied (en ramassant des objets éparpillés à la surface), depuis un avion (pour voir apparaître des anomalies dans le relief) ou encore avec des appareils radars qui donneront une indication sur d'éventuels bâtiments enfouis.",
		ossuary: "Ossuaire",
		ossuaryDesc:
			"Amas d’ossements. Cela peut aussi être un bâtiment où les ossements humains sont entassés.",

		palaeolithic: "Paléolithique supérieur",
		palaeolithicDesc:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		magdalenian: "Magdalénien",
		magdalenianDesc:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		neolithic: "Néolithique",
		neolithicDesc:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		antiquity: "Antiquité",
		antiquityDesc:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen  ge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		lateRoman: "Bas-Empire",
		lateRomanDesc:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		highMiddleAges: "Haut-Moyen-Âge",
		highMiddleAgesDesc: "Période qui s’étend de la fin de l’Antiquité à la fin de la période carolingienne, soit à la fin du Xe siècle.",
		classicalMiddleAges: "Moyen-Âge classique",
		classicalMiddleAgesDesc:
			"aussi appelée Moyen- ge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
	},
	{
		id: "en",
		flag: `url(${flagEn})`,
		lang: "English",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "Sur les traces de l'Homme",
		start: "Démarrer",
		/* Menu */
		timeline: "Frise",
		map: "Carte",
		glossary: "Lexique",
		/* Map */
		repereMap: "Repérez-vous sur la carte",
		enjoyExp:
			"Pour profiter à fond de l'expérience Furfooz, nous vous conseillons de partager votre localisation",
		letsgo: "C'est parti !",
		notNow: "Pas pour le moment",
		ok: "Ok",
		dotInactiv1: "Ce point n'est pas encore actif !",
		dotInactiv2: "Vous devez d'abord déverouiller les points précédents.",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Un jeu immersif pour découvrir les trésors de Furfooz sur les traces d’Edmond, le célèbre archéologue belge.",
		manyMarquage:
			"Rendez-vous sur les différents marqueurs du parcours et trouvez sur place le code secret qui révélera des contenus exclusif et de nombreuses surprises !",
		resetExp: "Réinitialiser l'expérience",
		credits: "Crédits",
		timescopeProject: "Un projet Timescope",
		creditsContent: "%bContenus%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotographie%b Photographe",
		creditsTexts: "%bTextes %bOona Farell",
		creditsDesign: "%bDesign de l'application mobile%b Felix Marquette",
		/* SecretCode */
		validate: "Valider",
		bravo: "Bravo !",
		continue: "Continuer",
		slideToDiscover: "Cliquer pour continuer",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Légende de l'image",
		backMap: "Retour à la carte",
		/* Timeline */
		timelineTitle: "Frise Chronologique",
		scrollArrowsExpl:
			"Pssst... Si vous êtes fatigués de scroller, utilisez les flèches pour vous déplacer plus rapidement d'une période à l'autre !",
		explanationTimeline:
			"L'époque moderne ne représente qu'une petite fraction de l'histoire de l'humanité. Serez-vous capable de remontrer jusqu'aux traces des premiers humains à Furfooz dans cette frise où chaque pixel correspond à 1 an ?",
		timelineBottom: "Vous êtes arrivés au bout ! Bravo :)",
		timelineBackFutur: "Retour vers le futur",
		/* Glossary */
		explanationGlossaryLine1:
			"Voici les termes techniques que vous avez pu rencontrer jusqu'à présent.",
		explanationGlossaryLine2:
			"Découvrez d'autres points d'intérêts pour remplir votre lexique !",
		unlockTo: "Débloqué aux ",
		glossaryUnlock: "Nouveau terme ajouté au lexique !",
		goGlossary: "Voir le lexique",

		/* Cookies */
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour sauvegardez vos progrès dans le jeu. En poursuivant l’expérience vous acceptez notre usage de cookies.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire notre politique de confidentialité.",
		/* PrivatePolicy */
		privatePolicyTitle: "Politique de confidentialité",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Vous avez résolu la première énigme !",
		thermalBathsRiddleTitle: "Pierre calcaire",
		thermalBathsRiddleDesc:
			"Fantastique ! En effet, les thermes sont bâtis à l’aide de pierres calcaires. Pour lier l’ouvrage, les Romains ont utilisé du mortier de teinte grisâtre compact et du fin gravier de rivière. Et puis, le plateau de Hauterecenne est fait de pierres calcaires, il n’y a plus qu’à se servir.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Félicitations pour cette deuxième énigme !",
		hauterecenneRiddleTitle: "Pièce frappée",
		hauterecenneRiddleDesc:
			"Bien joué ! Sur le plateau de Hauterecenne, les archéologues ont découvert des pièces de monnaie en grande quantité… et traversant bien des siècles !",

		/* Nutons */
		nutonsRiddleDisc: "Encore une énigme réussie !",
		nutonsRiddleTitle: "Bois de renne gravé",
		nutonsRiddleDesc:
			"Bravo ! Au Trou des Nutons, on a découvert un bois de renne sur lequel est gravé une tête de bison, et lorsqu’on le retourne, on y voit un mammouth !",

		/* Frontal */
		frontalRiddleDisc: "Vous avez résolu la quatrième énigme !",
		frontalRiddleTitle: "Os frontal d’un adolescent",
		frontalRiddleDesc:
			"Félicitation ! Edouard Dupont a découvert en 1864 un os frontal appartenant à un adolescent ainsi que deux crânes humains intacts conservés dans un ossuaire.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Encore une énigme, continuez ainsi !",
		goldenPaddleRiddleTitle: "Bout d’une roche avec des sédiments",
		goldenPaddleRiddleDesc:
			"Bravo ! Ce bout de roche est une source d’information précieuse pour les paléontologues. À l’intérieur, on y voit des sédiments, un dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide. Grâce à eux, nous pouvons dater le passage de l’Homme dans la grotte.",

		/* Reuviau */
		reuviauRiddleDisc: "C'était la dernière énigme !",
		reuviauRiddleTitle: "Silex taillé magdalénien",
		reuviauRiddleDesc:
			"Fantastique ! Vous êtes arrivés à la fin de votre parcours. Les silex taillés sont les outils emblématiques de la Préhistoire. Les cavités de Furfooz ont livré de belles séries de silex taillés magdaléniens et du Néolithique moyen.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen-Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen-Âge centrale, cette période comprise entre les XI et XIIIe siècles de notre ère se caractérise par l'organisation du système féodal, des grands seigneurs et de la construction de leurs châteaux.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration: "L'antiquité ça dure longtemps.",
		basEmpireExpl: "Pas grand chose à dire sur le bas empire.",
		timelineNeolithic: "Néolithique",
		neolithicDuration: "Le néolithique ça dure très longtemps",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Le Paléolithique ça dure très très très très très très très très très très très très très très très très très très très très très longtemps",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Le Magdalénien ça dure très très très très très très très longtemps",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Accueil",

		/* thermalBaths */
		thermalBaths: "Thermes gallo-romains",
		thermalBathsTitle: "Thermes gallo-romains",
		thermalBathsSubtitle:
			"Les thermes, un lieu incontournable de la vie à l’époque romaine/dans le quotidien des romains !",
		thermalBathsSec1Title: "Furfooz romain",
		thermalBathsSec1Content:
			"En toute terre où les romains s’installent, il est un lieu incontournable : les thermes. Furfooz ne déroge pas à la règle ! Et oui, au Bas-Empire, après la conquête romaine de l’Europe, le plateau de Hauterecenne devient un fortin très important occupé par des auxiliaires barbares au service de Rome. Il faut donc y construire des infrastructures…",
		thermalBathsSec2Title: "Comment ça fonctionne, des thermes ?",
		thermalBathsSec2Content:
			"D’une superficie modeste de 75m2, le bâtiment comprend tous les éléments traditionnels de ces établissements romains, à savoir : une salle au bain chaud (caldarium) et au bain tiède (tepidarium), ainsi qu’une salle au bain froid (frigidarium). La pièce principale est chauffée par %lhypocauste%l, tandis que le bain froid est situé à l’écart, en saillie sur une petite pièce quadrangulaire.",
		thermalBathsEncart1Title: "Pourquoi donc un établissement thermal ici ?",
		thermalBathsEncart1Content:
			"Il plane bien des mystères autour de la présence de thermes sur le site de Furfooz : De quelle période sont-ils datés? Comment les romains ont-ils réussi à acheminer l’eau jusqu’à l’édifice? Comment expliquer l’isolement total en cet endroit de cet ensemble thermal du Haut-Empire ? Antérieur au IVe siècle, certains auteurs justifient son existence par la présence possible d’une villa Gallo-romaine à proximité, mais aucune %lprospection%l n’a jamais pu en localiser l’emplacement. En ce qui concerne l’acheminement de l’eau, les hypothèses archéologiques vont bon train. En l’absence de sources sur le plateau, le géologue Van de Poel envisage par exemple l’existence d’une conduite d’eau qui aurait alimenté les thermes au départ d’une source située à quelques 600m delà, au Fond-des-Vaux. Les %larchéologues%l ont encore de nombreuses questions à élucider !",
		thermalBathsSec3Title: "Plus qu’un bain, un lieu social et civique !",
		thermalBathsSec3Content:
			"Si les thermes ont pour fonction première d’assurer l’hygiène de la cité, ces bains publics sont aussi un haut lieu de sociabilité. Dans la société romaine, on y va tous les jours pour y rencontrer ses amis ou encore y faire du sport, et ce qu’importe son statut social ! Ils sont ouverts à tous, sans distinction de classe sociale, aux hommes comme aux femmes (dans des parties ou à des heures différentes).",
		thermalBathsEncart2Title:
			"L’archéologie expérimentale, une aventure scientifique !",
		thermalBathsEncart2Content:
			"Ils sont passionnés ces archéologues ! D’abord exploré en 1876-1877 par Alfred Bequet de la Société archéologique de Namur, il faut attendre le XXe siècle, de 1956 à 1958, pour qu’on entreprenne une reconstruction fidèle et grandeur nature des thermes romains. Grande aventure scientifique, l’archéologie expérimentale permet de reconstituer ces édifices disparus, de la façon la plus rigoureuse possible, et ainsi rendre accessible la pratique et l’Histoire à un large public. Un bel exemple de pédagogie !",
		thermalBathsSec4Title:
			"Quand l’horizon du Moyen- ge sonne le glas des thermes gallo-romains",
		thermalBathsSec4Content:
			"Si les thermes sont parfaitement visibles grâce à leurs reconstructions, l’histoire du site est bien plus complexe. En effet, à l’orée du Moyen- ge, le plateau de Hauterecenne est progressivement abandonné par le camp romain. Vers le dernier tiers du IVe siècle, le site des thermes va alors prendre une nouvelle fonction, celle d’une petite %lnécropole%l, une fois le bâtiment détruit.",
		thermalBathsEncart3Title:
			"Bâtir une nécropole sur les ruines de thermes gallo-romain",
		thermalBathsEncart3Content:
			"Pour enterrer ses morts, la communauté occupant le site fortifié à la fin du IVe siècle jette son dévolu sur l’espace thermal, devenu terrain vague. La surface nécessaire pour y enfouir les défunts n’est pas très grande puisqu’on compte environ vingt-cinq sépultures fouillées. Les tombes sont creusées à même le sol, sans fondation ou infrastructure. L’étude de cette nécropole tardive dit beaucoup de choses de ses habitants, notamment la découverte de nombreux objets. Car oui, les haches laissent penser que la population de Furfooz seraient de nouveaux arrivants barbares, mais les beaux ceinturons enterrés appartiennent plus à la culture militaire romaine tardive. D’autres trouvailles compliquent l’identification certaine de ce peuple. Quoi qu’il en soit, c’est un beau mélange de culture à Furfooz !",
		thermalBathsSec5Title:
			"Actualité de l’archéologie, le site à nouveau fouillé !",
		thermalBathsSec5Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Hauterecenne */
		hauterecenne: "Plateau fortifié de Hauterecenne",
		hauterecenneTitle: "Plateau fortifié de Hauterecenne",
		hauterecenneSubtitle: "Une forteresse, plusieurs vies !",
		hauterecenneQuote1:
			"“L’homme, à toutes les époques, s’est établi dans cette localité [Furfooz] suivant les diverses conditions de son temps.”",
		hauterecenneQuoteAuthor1:
			"Edouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Les traces de fortifications scrutées à la loupe tardivement",
		hauterecenneSec1Content:
			"Le champ de recherche préféré à Furfooz, c’est la paléontologie. Et pourtant, il existe d’autres traces de la présence humaine bien plus tardives et qui méritent une pleine attention ! Le plateau de Hauterecenne, au cœur de cette activité humaine du %lBas-Empire%l et du Haut Moyen Âge, est fouillé marginalement au XIXe siècle, mais il faut attendre les années 1930, puis 1980 pour qu’il fasse l’objet d’études approfondies.\nEn route pour découvrir tous ses secrets !",
		hauterecenneSec2Title:
			"Hauterecenne, un plateau stratégique pour l’installation… d’une garnison romaine !",
		hauterecenneSec2Content:
			"En étudiant les traces des anciens remparts, les archéologues ont mis au jour la présence d’une garnison romaine du Bas-Empire sur le site de Furfooz. Mais pourquoi s’installer ici ? Pour sa situation stratégique bien-sûr ! Le plateau de Hauterecenne est un haut promontoire dominant la rivière, qui dispose d’une protection naturelle de ce côté mais aussi sur son flanc où le Ry des Vaux a lui-même creusé une petite vallée escarpée. Ainsi, situé au-dessus d’une falaise abrupte surplombant la Lesse de 60 mètres, il est très facile de fortifier l site et de se parer contre toute attaque ennemie ! Le plateau porte encore les traces bien conservées d’un certain nombre de structures anciennes et d’éléments de fortifications de périodes différentes. Les archéologues vous racontent.",
		hauterecenneEncart1Title:
			"Les archéologues étudient ces lieux stratégiques",
		hauterecenneEncart1Content:
			"« Depuis le milieu du XIXème siècle, on y a conduit plusieurs campagnes de fouilles et le grand mérite de Raymond Brulet est d’avoir repris l’étude du site [...]. Le site a été ainsi occupé depuis l’époque romaine jusqu’au XIIIème siècle avec des temps forts au milieu du IIIème siècle et au début du Vème siècle. » Raymond Brulet, La fortification de Hauterecenne à Furfooz, (publication d'histoire de l'Art et d'Archéologie de l'Université catholique de Louvain XIII), 1978",
		hauterecenneSec3Title: "Puis d’une petite forteresse médiévale !",
		hauterecenneSec3Content:
			"Raymond Brulet nous met sur la piste… La forteresse aurait donc évolué au fil du temps ? Et oui, après la chute de l’Empire romain et le départ de ses troupes, le site se fortifie épisodiquement. Nous n’avons que peu d’informations quant aux habitants qui s’y installèrent, en revanche la présence d’une maison forte et l’ajout de remparts suggèrent l’installation d’un petit seigneur régional. À la fin du XIIIème siècle, le site perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		hauterecenneEncart2Title: "Savez-vous ce qu’est une maison forte ?",
		hauterecenneEncart2Content:
			"La maison forte est l’ancêtre des châteaux forts. De taille modeste, il n’est possible d’y pénétrer que par le 1er étage. Ainsi, si la maison est attaquée, l’escalier peut être retiré et les occupants protégés !",
		hauterecenneSec4Title:
			"Une superposition d’époques, comment faire pour les distinguer ?",
		hauterecenneSec4Content:
			"Pour retracer l’histoire et l’évolution de l’occupation humaine sur un site, l’archéologue outillé, mais s’entoure de spécialistes. Dans le cas du plateau fortifié, il faut pouvoir distinguer les époques, alors qu’elles se superposent. On travaille alors sur une chronologie relative et on fait appel à des comparaisons site par site. Ce long cheminement a permis de faire une découverte importante : la présence de deux murs de barrage.",
		hauterecenneSec5Title: "L’affaire des deux murs de barrage",
		hauterecenneSec5Content:
			"Et oui, les différentes époques sont visibles sur les murs ! Le premier mur de barrage, le plus extérieur, date du début du Bas-Empire romain. Très étroit et construit selon la même technique que les thermes, cela laisse à penser qu’il devait être muni soit d’un talus de terre appuyé contre lui, soit d’un parapet en bois, ancré dans le mur. Pour une raison que l’on ignore, un second mur de barrage a été élevé ensuite. Il bénéficie d’une large porte charretière et son épaisseur est cette fois plus commune. Il remonte sans doute au Ve siècle. Lors de la dernière mise en défense du site, à l’époque du Moyen- ge classique, ce mur a été bouché, puis surélevé. Son système de circulation au sommet de l’infrastructure repose sur un série de piliers à arcatures.",
		hauterecenneSec6Title: "Et si on fouillait encore ce lieu ?",
		hauterecenneSec6Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsSubtitle: "Entre découvertes paléontologiques et légende populaire",
		nutonsSec1Title: "Le Trou des nutons, première grotte explorée",
		nutonsSec1Content:
			"En 1864, notre cher Edouard Dupont se lance en premier dans la fouille du trou des Nutons. S’il le choisit comme point de départ de son exploration, c’est parce qu’il présente toutes les caractéristiques idéales à l’implantation d’un campement magdalénien : bien abrité, logé à 40 m au-dessus du lit de la rivière donc proche d’une source d’eau… parfait pour cette population semi-nomade de chasseurs de rennes ou de chevaux !",
		nutonsSec2Title: "Quand l’étude des sols rencontre l’étude des Hommes",
		nutonsSec2Content:
			"Mais comment diable Edouard Dupont tire-t-il autant de conclusions au sujet d’une population si lointaine ? En effectuant ce l’on appelle en archéologie un sondage en archéologie. Sur une toute petite partie de la grotte, l'équipe de Dupont retire les couches successives du sol, jusqu’à 8 m de profondeur pour les étudier. Ces couches sont composées de sédiments, c’est-à-dire de dépôts organiques qui en disent beaucoup sur la période d’occupation et les modes de vie de nos ancêtres. Indéniablement, géologie et archéologie sont intimement liées.",
		nutonsSec3Title: "Des premières grandes découvertes",
		nutonsSec3Content:
			"Edouard Dupont ne s’arrête pas aux sondages ! Il fait dynamiter les stalagmites et les blocs de pierre. Sous ceux-ci, il fait de grandes découvertes : des débris  d’une vingtaine d’espèces animales, des couteaux et des flèches en silex, des pièces de monnaie de l’époque gallo-romaine et divers ustensiles postérieurs. Par contre, il n’y a pas la moindre trace d’ossements humains… Pourtant, tout portait à croire que la découverte de crânes était possible !",
		nutonsSec4Title: "Le %lpaléontologue%l écoute les légendes populaires",
		nutonsSec4Content:
			"Et oui ! Si Edouard Dupont fait le choix de ce trou pour commencer son exploration, c’est aussi car une légende lui est associée… Selon la croyance populaire, les Nutons étaient des nains à l’aspect vieux et chétif, mais actifs, très malicieux et fort habiles de leurs mains. Ils vivaient cachés dans les rochers, les cavernes et les ruines. Pendant la nuit, pour un peu de nourriture et quelques friandises déposées à l’entrée de leurs  gîtes, ces êtres surnaturels réparaient les ustensiles, les objets mobiliers, raccommodaient des haillons et blanchissaient le linge des villageoises. À la belle saison, ils sortaient en nombre pour se livrer à de joyeuses danses dans les prés. Taquins, ils pouvaient être très susceptibles et se vengeaient de ceux qui les bernaient ou cherchaient à découvrir leurs secrets.",
		nutonsEncart1Title: "Une explication scientifique de la légende ?",
		nutonsEncart1Content:
			"Edouard Dupont tente d’expliquer la petite taille des populations de Furfooz : « Les hommes de Grenelle, et surtout ceux de Furfooz, étaient de petite taille. Les premiers alignaient encore une moyenne d’1m62, mais les seconds descendaient à 1m53. C’est presque exactement la taille moyenne des Lapons. Toutefois, cette stature réduite n’excluait ni la vigueur ni l’agilité nécessaire aux populations sauvages. Les os des membres et du tronc sont robustes, et les saillies, les dépressions de leur surface, accusent un développement musculaire très prononcé. À part cette robusticité générale, supérieure à ce que l’on rencontre habituellement, les squelettes des hommes de Furfooz et de Grenelle ressemblent fort à celui des hommes d’aujourd’hui » (Edouard Dupont, « Sur les crânes de Furfooz », Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"Aussi mystérieux qu’emblématique, le Trou des Nutons a toujours de quoi vous surprendre ! Continuez votre exploration de la réserve pour découvrir bien d’autres secrets.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalSubtitle: "Entre habitat et sépulture",
		frontalSec1Title:
			"S’il est appelé Trou du Frontal, c’est grâce à une découverte !",
		frontalSec1Subtitle:
			"À votre avis, pourquoi ce nom ? Tout simplement car notre ami Edouard Dupont, en fouillant le trou en 1864, met la main dès les premiers coups de pioche sur un os frontal appartenant à un adolescent. Cette grande découverte est un événement dans le monde de la recherche et est annonciatrice de bien d’autres…",
		frontalSec2Title:
			"la découverte de deux crânes très différents, une énigme à élucider",
		frontalSec2Subtitle:
			"Le 10 décembre de la même année, le paléontologue dégage, en présence d’invités de marque, deux crânes humains intacts conservés dans un ossuaire. Cela ouvre un champ d’hypothèses immense quant à la période d’occupation de ces grottes, mais aussi aux modes de vie de nos ancêtres.",
		frontalEncart1Title: "Récit de fouilles par Dupont",
		frontalEncart1Content:
			"“Cette petite caverne, tant par ses données géologiques que par les lumières qu’elle a répandues sur l’ethnographie ancienne, est la plus importante qui ait encore été fouillée dans notre pays. Il est certain que si l’explorateur eût souvent vu ses recherches couronnées d’un succès aussi complet, les laborieux travaux, entrepris dans notre région, eussent été fort abrégés, et il resterait aujourd’hui bien peu de chose à dévoiler sur les habitants de la Belgique à l’époque quaternaire. [...] Nous examinerons plus loin les données que les restes des repas dans l’abri extérieur et l’amas d’ossements humains nous fournissent sur les coutumes funéraires de l’antique peuplade.” \n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Se tromper fait partie de la recherche paléontologique !",
		frontalSec3Subtitle:
			"En étudiant ces ossements, Dupont les date à tort de l’époque des chasseurs de rennes. Mais être paléontologue c’est aussi se tromper ! Des études plus approfondies sur ces crânes et de l’ensemble des matériaux découverts sur le site ont mis au jour une confusion de la part de Dupont entre des traces de Magdaléniens, les fameux chasseurs, et celles de populations vivant au Néolithique.",
		frontalEncart2Title:
			" Quand la théorie de Darwin éclaire le monde paléontologique",
		frontalEncart2Content:
			"Les fouilles entreprises par Dupont sont considérées comme le premier argument anatomique en faveur des théories de  Darwin, retranscrites dans son ouvrage L’origine des espèces publié en 1859, quelques années avant ses découvertes. Grâce à ces crânes, il comprend l’évolution de l’espèce humaine en fonction des périodes d’occupation. Car oui, des Hommes de deux ères vivaient au trou du Frontal !",
		frontalSec4Title: "Des Hommes de deux ères : la vie, la mort",
		frontalSec4Subtitle:
			"Au Trou du Frontal, les fouilles archéologiques ont mis au jour à la fois des silex taillés datant de l’époque %lMagdalénienne%l, mais aussi de nombreux ossements, d’au moins 16 individus (hommes, femmes et enfants) situés nettement au-dessus de la couche du niveau magdalénien. Tout porte à croire qu’il s’agit-là de vestiges d’une sépulture datant du Néolithique moyen. Près de 10 000 ans les séparent et pourtant, tous ont choisi le trou du Frontal pour refuge !",
		frontalSec5Title: "Vivre et mourir au Trou du Frontal",
		frontalSec5Subtitle:
			"L’amas d’ossements suggère un espace funéraire plutôt qu’un habitat. En effet, le procédé d’inhumation, c’est-à-dire d’enterrer ses morts s’étend à tout le territoire actuel de la Belgique pendant le Néolithique, il y a 7000 ans. Le Trou du Frontal en est un parfait exemple puisqu’il est même fermé par une dalle, comme on le ferait pour une tombe, ce qui confirme qu’il s’agit-là d’une sépulture secondaire datant du Néolithique moyen.",
		frontalSec6Title:
			"Du trou du Frontal au trou de la Mâchoire… il n’y a que 10 mètres à parcourir !",
		frontalSec6Subtitle:
			"La pratique funéraire est très présente sur le site de Furfooz. À quelques pas de trou du Frontal, le trou de la Mâchoire avait sans doute la même fonction puisqu’on y a découvert cinq squelettes dans un état remarquable. Et, nous le savons, étudier les morts c’est comprendre la vie !\nPassez à la buvette située non loin du trou du Frontal pour profiter pleinement des beautés de la nature.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleSubtitle:
			"Une mine d’informations sur l’histoire de l’humanité !",
		goldenPaddleSec1Title:
			"Pourquoi diable étudier la roche pour en apprendre sur les Hommes ?",
		goldenPaddleSec1Subtitle:
			"“Les études de cette nature, qui puisent leurs éléments dans les sciences géologiques, sont donc du ressort exclusif des sciences naturelles. Par ces investigations, la géologie donne la main à l’histoire et l’aide à restaurer le passé le l’humanité.” Edouard Dupont, Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse,1872\n\nCes quelques mots de Edouard Dupont transmettent bien l’importance de l’étude de la roche, la sédimentation, dans la compréhension de l’histoire de l’humanité. On vous explique.",
		goldenPaddleSec2Title:
			"La sédimentation, une mine d’or pour les scientifiques !",
		goldenPaddleSec2Subtitle:
			"En décryptant les modifications du milieu naturel, celui que l’on appelle le géomorphologue nous renseigne sur les activités humaines qui l’ont façonné. En étudiant les sédiments, il détermine l’origine des dépôts (alluvions, ruissellements, remblais…) ou leur âge. Il observe aussi la succession des strates de sédiments visible grâce aux changements de couleur ou de texture des sols (argiles, sables, cailloux…). Chaque strate correspondant à une époque, la méthode stratigraphique permet de déterminer la nature et la chronologie des événements intervenus. À la Gatte d’Or, les couches sédimentaires sont très visibles et renseignent aussi bien sur la présence humaine que sur les origines même de Furfooz.",
		goldenPaddleSec3Title:
			"Des fouilles qui détériorent le patrimoine, cela arrive aussi…",
		goldenPaddleSec3Subtitle:
			"Au début du XXe siècle, le site de Furfooz n’est plus habité depuis longtemps mais est un haut lieu touristique payant. Hélas ! Après les nombreuses fouilles de Dupont, rien n’a été prévu pour protéger l’intérieur des grottes. L’afflux des vacanciers n’a fait qu’empirer les choses avec une détérioration à l’intérieur des  grottes dont celle de la Gatte d’or : bris de concrétions calcaires et altération de la teinte des  draperies suite à un mauvais emploi des bougies utilisées pour l’éclairage.",
		goldenPaddleEncart1Title: "La légende raconte…",
		goldenPaddleEncart1Content:
			"La légende du pays raconte qu’une gatte, une chèvre en wallon, vivrait dans les grottes de Furfooz et garderait un trésor… Cette histoire populaire remonte à une lointaine époque, où le tissu manquait et les banques n’existaient pas. Les gens confectionnaient de petits sacs en peau poilue et cachaient leurs deniers où ils pouvaient. Si bien que parfois, ils les oubliaient ou mourraient avec leur secret… Les malheureux sans mémoire font des heureux bien plus tard ! De cette légende naît la fameuse gatte toute d’or vêtue !",
		goldenPaddleSec4Title:
			"Aujourd’hui, la Gatte d’or est un spot naturel incontournable !",
		goldenPaddleSec4Subtitle:
			"Heureusement, l’association Ardenne & Gaume œuvre aujourd’hui pour la préservation de ce patrimoine. La présence humaine se fait quasi invisible et laisse place à un paysage naturel exceptionnel. La vue y est à couper le souffle !",

		/* Reuviau */
		reuviau: "Trou Reuviau",
		reuviauTitle: "Trou Reuviau",
		reuviauSubtitle: "Au Trou Reuviau, on se met à table !",
		reuviauSec1Title: "Reuviau, un trou discret mais riche en savoir",
		reuviauSec1Subtitle:
			"À la fin de votre promenade dans la réserve naturelle de Furfooz, n’oubliez pas de vous arrêter au trou Reuviau !  Modeste, il est pourtant très intéressant pour les paléontologues, notamment pour connaître mieux les pratiques alimentaires des Magdaléniens. On vous explique.",
		reuviauEncart1Title: "Fouilles du Reuviau par E.Dupont",
		reuviauEncart1Content:
			"“Une autre petite caverne, le trou Reuviau, dans un ravin sur le chemin qui conduit de la Lesse à Furfooz, a fourni, dans les mêmes circonstances géologiques, des ossements de cheval, de renne, d’ours, etc., brisés de main d'homme.”\n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "Le repas des Hommes à l’âge du renne",
		reuviauSec2Subtitle:
			"Edouard Dupont, puis bien d’autres paléontologues, ont découvert une série d’ossement de rennes et de chevaux qui portent à croire qu’ils étaient des mets de choix pour ces hommes des cavernes… cela a même donné le nom à l’âge du renne, pour dire ! Des silex mis au jour dans le trou en disent beaucoup sur la façon dont ces hommes dépeçaient les bêtes, aussi bien pour se nourrir que pour se vêtir.",
		reuviauEncart2Title: "Dupont défini l’âge de pierre",
		reuviauEncart2Content:
			"“La chasse leur fournissait leurs aliments et leurs grossiers vêtements ; l’os et surtout la pierre-à-feu leur donnaient des outils et des armes, sans qu’elles eussent pu arriver à produire des métaux ou seulement à en soupçonner l’existence. Aussi a-t-on désigné, sous le nom d’âges de pierre, ces époques lointaines et de longue durée.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "La chasse à la Préhistoire",
		reuviauSec3Subtitle:
			"Durant le paléolithique, le renne, passant en troupeaux dans les vallées, est le gibier de prédilection des hommes préhistoriques. De nombreuses traces de ces chasses ont été retrouvées dans les diverses cavernes de Furfooz. Animal grégaire et migrateur, le renne était sans doute aussi un animal facile à chasser, et à chasser en masse… Mais aussi à faire sécher ! L'ensemble de ces facteurs, étudiés par les paléontologues, nous en apprennent toujours un peu plus sur nos ancêtres !",
		reuviauSec4Title: "L’enquête dans les trous de Furfooz continue !",
		reuviauSec4Subtitle:
			"Vous arrivez à la fin de votre exploration mais, n’en doutez pas, vous n’avez découvert qu’une infime partie de la réserve naturelle de Furfooz ! N’hésitez pas à revenir pour en explorer ses moindres recoins, sa faune et sa flore luxuriantes, protégées depuis près de 70 ans !\nÀ bientôt.",

		furfoozMystery: "Les mystères de Furfooz",
		archaeology: "Archéologie",
		doYouKnow: "Bon à savoir",
		anecdote: "Anecdote",
		edouardDupont: "L'anecdote Dupont",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paléontologue",
		palaeontologistDesc:
			"celui qui étudie le Vivant, qu'il s'agisse d'êtres humains, d'animaux mais aussi de végétaux. Il s’intéresse à l’évolution biologique de ces êtres, sur une période immense qui remonte aux premières traces de vie sur Terre, il y a environ 3,5 milliards d’années.",
		archaeologist: "Archéologue",
		archaeologistDesc:
			"celui qui étudie les civilisations humaines passées à partir de traces matérielles qui en subsistent (bâtiments, statues, pièces de monnaie, tombes, manuscrits, outils de la vie quotidienne…).",
		geologist: "Géologue",
		geologistDesc:
			"celui qui étudie, décrit et explique la nature, l'origine et la situation des roches, des terrains, etc., constituant la croûte terrestre.",
		anthropologist: "Anthropologue",
		anthropologistDesc:
			"celui qui étudie l’homme et son évolution. En archéologie, il analyse les données biologiques et les conditions de vie des individus à partir du squelette. Il enregistre les structures funéraires, la position des défunts et des ossements, les objets associés (bijoux, vêtements, monnaies,…) c’est-à-dire tous les éléments qui lui permettent d’identifier les gestes effectués par les vivants.",
		sediment: "Sédiment",
		sedimentDesc:
			"dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide.",
		experimentalArchaeology: "Archéologie expérimentale",
		experimentalArchaeologyDesc:
			"Branche de l’archéologie, cette discipline consiste à restituer, à travers l’expérimentation, des bâtiments ou objets grâce aux techniques de construction et de fabrication d’époque… l’application de l’archéologie grandeur nature en somme !",
		necropolis: "Nécropole",
		necropolisDesc:
			"Equivalent d’un cimetière à l’Antiquité. Les sépultures peuvent être situées dans des excavations souterraines ou à ciel ouvert.",
		hypocaust: "Hypocauste",
		hypocaustDesc:
			"Local souterrain renfermant un système destiné à chauffer les bains, les salles des thermes.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"Ensemble des théories biologiques de Darwin et de ses disciples selon lesquels la sélection naturelle est le facteur essentiel de la transformation des espèces vivantes.",
		prospecting: "Prospection (archéologie)",
		prospectingDesc:
			"Méthode globale d'appréhension de l'ensemble des traces des aménagements apportés par les sociétés humaines à leur environnement physique et vivant.",
		ossuary: "Ossuaire",
		ossuaryDesc:
			"Amas d’ossements. Cela peut aussi être un bâtiment où les ossements humains sont entassés.",

		palaeolithic: "Paléolithique",
		palaeolithicDesc:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -34 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		magdalenian: "Magdalénien",
		magdalenianDesc:
			"Cette période, aussi appelée par Edouard Dupont l’âge du renne, correspond à la dernière phase du paléolithique supérieur, entre environ 17 000 et 12 000 ans avant notre ère. Encore au cœur d’une ère glaciaire, on y voit l'essor du travail de l'os et le perfectionnement du petit outillage de pierre.",
		neolithic: "Néolithique",
		neolithicDesc:
			"Période de la préhistoire qui appartient à l'époque de la pierre polie et qui s'étend, selon les sites, de - 7 000 à - 2 500 ans.",
		antiquity: "Antiquité",
		antiquityDesc:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen  ge, c’est-à-dire la période entre 3500 av. J.-C. et 476 (date de la dislocation de l’Empire Romain d’Occident).",
		lateRoman: "Bas-Empire",
		lateRomanDesc:
			"Période de l’Empire romain à partir du milieu du IVe siècle jusqu'à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen- ge.",
		classicalMiddleAges: "Moyen-Âge classique",
		classicalMiddleAgesDesc:
			"Aussi appelée Moyen- ge centrale, cette période comprise entre les XI et XIIIe siècles de notre ère se caractérise par l’organisation du système féodal, des grands seigneurs et de la construction de leurs châteaux.",
	},
	{
		id: "de",
		flag: `url(${flagDe})`,
		lang: "Deutsch",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "Sur les traces de l'Homme",
		start: "Démarrer",
		/* Menu */
		timeline: "Frise",
		map: "Carte",
		glossary: "Lexique",
		/* Map */
		repereMap: "Repérez-vous sur la carte",
		enjoyExp:
			"Pour profiter à fond de l'expérience Furfooz, nous vous conseillons de partager votre localisation",
		letsgo: "C'est parti !",
		notNow: "Pas pour le moment",
		ok: "Ok",
		dotInactiv1: "Ce point n'est pas encore actif !",
		dotInactiv2: "Vous devez d'abord déverouiller les points précédents.",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Un jeu immersif pour découvrir les trésors de Furfooz sur les traces d’Edmond, le célèbre archéologue belge.",
		manyMarquage:
			"Rendez-vous sur les différents marqueurs du parcours et trouvez sur place le code secret qui révélera des contenus exclusif et de nombreuses surprises !",
		resetExp: "Réinitialiser l'expérience",
		credits: "Crédits",
		timescopeProject: "Un projet Timescope",
		creditsContent: "%bContenus%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotographie%b Photographe",
		creditsTexts: "%bTextes %bOona Farell",
		creditsDesign: "%bDesign de l'application mobile%b Felix Marquette",
		/* SecretCode */
		validate: "Valider",
		bravo: "Bravo !",
		continue: "Continuer",
		slideToDiscover: "Cliquer pour continuer",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Légende de l'image",
		backMap: "Retour à la carte",
		/* Timeline */
		timelineTitle: "Frise Chronologique",
		scrollArrowsExpl:
			"Pssst... Si vous êtes fatigués de scroller, utilisez les flèches pour vous déplacer plus rapidement d'une période à l'autre !",
		explanationTimeline:
			"L'époque moderne ne représente qu'une petite fraction de l'histoire de l'humanité. Serez-vous capable de remontrer jusqu'aux traces des premiers humains à Furfooz dans cette frise où chaque pixel correspond à 1 an ?",
		timelineBottom: "Vous êtes arrivés au bout ! Bravo :)",
		timelineBackFutur: "Retour vers le futur",
		/* Glossary */
		explanationGlossaryLine1:
			"Voici les termes techniques que vous avez pu rencontrer jusqu'à présent.",
		explanationGlossaryLine2:
			"Découvrez d'autres points d'intérêts pour remplir votre lexique !",
		unlockTo: "Débloqué aux ",
		glossaryUnlock: "Nouveau terme ajouté au lexique !",
		goGlossary: "Voir le lexique",

		/* Cookies */
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour sauvegardez vos progrès dans le jeu. En poursuivant l’expérience vous acceptez notre usage de cookies.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire notre politique de confidentialité.",
		/* PrivatePolicy */
		privatePolicyTitle: "Politique de confidentialité",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Vous avez résolu la première énigme !",
		thermalBathsRiddleTitle: "Pierre calcaire",
		thermalBathsRiddleDesc:
			"Fantastique ! En effet, les thermes sont bâtis à l’aide de pierres calcaires. Pour lier l’ouvrage, les Romains ont utilisé du mortier de teinte grisâtre compact et du fin gravier de rivière. Et puis, le plateau de Hauterecenne est fait de pierres calcaires, il n’y a plus qu’à se servir.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Félicitations pour cette deuxième énigme !",
		hauterecenneRiddleTitle: "Pièce frappée",
		hauterecenneRiddleDesc:
			"Bien joué ! Sur le plateau de Hauterecenne, les archéologues ont découvert des pièces de monnaie en grande quantité… et traversant bien des siècles !",

		/* Nutons */
		nutonsRiddleDisc: "Encore une énigme réussie !",
		nutonsRiddleTitle: "Bois de renne gravé",
		nutonsRiddleDesc:
			"Bravo ! Au Trou des Nutons, on a découvert un bois de renne sur lequel est gravé une tête de bison, et lorsqu’on le retourne, on y voit un mammouth !",

		/* Frontal */
		frontalRiddleDisc: "Vous avez résolu la quatrième énigme !",
		frontalRiddleTitle: "Os frontal d’un adolescent",
		frontalRiddleDesc:
			"Félicitation ! Edouard Dupont a découvert en 1864 un os frontal appartenant à un adolescent ainsi que deux crânes humains intacts conservés dans un ossuaire.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Encore une énigme, continuez ainsi !",
		goldenPaddleRiddleTitle: "Bout d’une roche avec des sédiments",
		goldenPaddleRiddleDesc:
			"Bravo ! Ce bout de roche est une source d’information précieuse pour les paléontologues. À l’intérieur, on y voit des sédiments, un dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide. Grâce à eux, nous pouvons dater le passage de l’Homme dans la grotte.",

		/* Reuviau */
		reuviauRiddleDisc: "C'était la dernière énigme !",
		reuviauRiddleTitle: "Silex taillé magdalénien",
		reuviauRiddleDesc:
			"Fantastique ! Vous êtes arrivés à la fin de votre parcours. Les silex taillés sont les outils emblématiques de la Préhistoire. Les cavités de Furfooz ont livré de belles séries de silex taillés magdaléniens et du Néolithique moyen.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen-Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen-Âge centrale, cette période comprise entre les XI et XIIIe siècles de notre ère se caractérise par l'organisation du système féodal, des grands seigneurs et de la construction de leurs châteaux.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration: "L'antiquité ça dure longtemps.",
		basEmpireExpl: "Pas grand chose à dire sur le bas empire.",
		timelineNeolithic: "Néolithique",
		neolithicDuration: "Le néolithique ça dure très longtemps",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Le Paléolithique ça dure très très très très très très très très très très très très très très très très très très très très très longtemps",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Le Magdalénien ça dure très très très très très très très longtemps",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Accueil",

		/* thermalBaths */
		thermalBaths: "Thermes gallo-romains",
		thermalBathsTitle: "Thermes gallo-romains",
		thermalBathsSubtitle:
			"Les thermes, un lieu incontournable de la vie à l’époque romaine/dans le quotidien des romains !",
		thermalBathsSec1Title: "Furfooz romain",
		thermalBathsSec1Content:
			"En toute terre où les romains s’installent, il est un lieu incontournable : les thermes. Furfooz ne déroge pas à la règle ! Et oui, au Bas-Empire, après la conquête romaine de l’Europe, le plateau de Hauterecenne devient un fortin très important occupé par des auxiliaires barbares au service de Rome. Il faut donc y construire des infrastructures…",
		thermalBathsSec2Title: "Comment ça fonctionne, des thermes ?",
		thermalBathsSec2Content:
			"D’une superficie modeste de 75m2, le bâtiment comprend tous les éléments traditionnels de ces établissements romains, à savoir : une salle au bain chaud (caldarium) et au bain tiède (tepidarium), ainsi qu’une salle au bain froid (frigidarium). La pièce principale est chauffée par %lhypocauste%l, tandis que le bain froid est situé à l’écart, en saillie sur une petite pièce quadrangulaire.",
		thermalBathsEncart1Title: "Pourquoi donc un établissement thermal ici ?",
		thermalBathsEncart1Content:
			"Il plane bien des mystères autour de la présence de thermes sur le site de Furfooz : De quelle période sont-ils datés? Comment les romains ont-ils réussi à acheminer l’eau jusqu’à l’édifice? Comment expliquer l’isolement total en cet endroit de cet ensemble thermal du Haut-Empire ? Antérieur au IVe siècle, certains auteurs justifient son existence par la présence possible d’une villa Gallo-romaine à proximité, mais aucune %lprospection%l n’a jamais pu en localiser l’emplacement. En ce qui concerne l’acheminement de l’eau, les hypothèses archéologiques vont bon train. En l’absence de sources sur le plateau, le géologue Van de Poel envisage par exemple l’existence d’une conduite d’eau qui aurait alimenté les thermes au départ d’une source située à quelques 600m delà, au Fond-des-Vaux. Les %larchéologues%l ont encore de nombreuses questions à élucider !",
		thermalBathsSec3Title: "Plus qu’un bain, un lieu social et civique !",
		thermalBathsSec3Content:
			"Si les thermes ont pour fonction première d’assurer l’hygiène de la cité, ces bains publics sont aussi un haut lieu de sociabilité. Dans la société romaine, on y va tous les jours pour y rencontrer ses amis ou encore y faire du sport, et ce qu’importe son statut social ! Ils sont ouverts à tous, sans distinction de classe sociale, aux hommes comme aux femmes (dans des parties ou à des heures différentes).",
		thermalBathsEncart2Title:
			"L’archéologie expérimentale, une aventure scientifique !",
		thermalBathsEncart2Content:
			"Ils sont passionnés ces archéologues ! D’abord exploré en 1876-1877 par Alfred Bequet de la Société archéologique de Namur, il faut attendre le XXe siècle, de 1956 à 1958, pour qu’on entreprenne une reconstruction fidèle et grandeur nature des thermes romains. Grande aventure scientifique, l’archéologie expérimentale permet de reconstituer ces édifices disparus, de la façon la plus rigoureuse possible, et ainsi rendre accessible la pratique et l’Histoire à un large public. Un bel exemple de pédagogie !",
		thermalBathsSec4Title:
			"Quand l’horizon du Moyen- ge sonne le glas des thermes gallo-romains",
		thermalBathsSec4Content:
			"Si les thermes sont parfaitement visibles grâce à leurs reconstructions, l’histoire du site est bien plus complexe. En effet, à l’orée du Moyen- ge, le plateau de Hauterecenne est progressivement abandonné par le camp romain. Vers le dernier tiers du IVe siècle, le site des thermes va alors prendre une nouvelle fonction, celle d’une petite %lnécropole%l, une fois le bâtiment détruit.",
		thermalBathsEncart3Title:
			"Bâtir une nécropole sur les ruines de thermes gallo-romain",
		thermalBathsEncart3Content:
			"Pour enterrer ses morts, la communauté occupant le site fortifié à la fin du IVe siècle jette son dévolu sur l’espace thermal, devenu terrain vague. La surface nécessaire pour y enfouir les défunts n’est pas très grande puisqu’on compte environ vingt-cinq sépultures fouillées. Les tombes sont creusées à même le sol, sans fondation ou infrastructure. L’étude de cette nécropole tardive dit beaucoup de choses de ses habitants, notamment la découverte de nombreux objets. Car oui, les haches laissent penser que la population de Furfooz seraient de nouveaux arrivants barbares, mais les beaux ceinturons enterrés appartiennent plus à la culture militaire romaine tardive. D’autres trouvailles compliquent l’identification certaine de ce peuple. Quoi qu’il en soit, c’est un beau mélange de culture à Furfooz !",
		thermalBathsSec5Title:
			"Actualité de l’archéologie, le site à nouveau fouillé !",
		thermalBathsSec5Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Hauterecenne */
		hauterecenne: "Plateau fortifié de Hauterecenne",
		hauterecenneTitle: "Plateau fortifié de Hauterecenne",
		hauterecenneSubtitle: "Une forteresse, plusieurs vies !",
		hauterecenneQuote1:
			"“L’homme, à toutes les époques, s’est établi dans cette localité [Furfooz] suivant les diverses conditions de son temps.”",
		hauterecenneQuoteAuthor1:
			"Edouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Les traces de fortifications scrutées à la loupe tardivement",
		hauterecenneSec1Content:
			"Le champ de recherche préféré à Furfooz, c’est la paléontologie. Et pourtant, il existe d’autres traces de la présence humaine bien plus tardives et qui méritent une pleine attention ! Le plateau de Hauterecenne, au cœur de cette activité humaine du %lBas-Empire%l et du Haut Moyen Âge, est fouillé marginalement au XIXe siècle, mais il faut attendre les années 1930, puis 1980 pour qu’il fasse l’objet d’études approfondies.\nEn route pour découvrir tous ses secrets !",
		hauterecenneSec2Title:
			"Hauterecenne, un plateau stratégique pour l’installation… d’une garnison romaine !",
		hauterecenneSec2Content:
			"En étudiant les traces des anciens remparts, les archéologues ont mis au jour la présence d’une garnison romaine du Bas-Empire sur le site de Furfooz. Mais pourquoi s’installer ici ? Pour sa situation stratégique bien-sûr ! Le plateau de Hauterecenne est un haut promontoire dominant la rivière, qui dispose d’une protection naturelle de ce côté mais aussi sur son flanc où le Ry des Vaux a lui-même creusé une petite vallée escarpée. Ainsi, situé au-dessus d’une falaise abrupte surplombant la Lesse de 60 mètres, il est très facile de fortifier l site et de se parer contre toute attaque ennemie ! Le plateau porte encore les traces bien conservées d’un certain nombre de structures anciennes et d’éléments de fortifications de périodes différentes. Les archéologues vous racontent.",
		hauterecenneEncart1Title:
			"Les archéologues étudient ces lieux stratégiques",
		hauterecenneEncart1Content:
			"« Depuis le milieu du XIXème siècle, on y a conduit plusieurs campagnes de fouilles et le grand mérite de Raymond Brulet est d’avoir repris l’étude du site [...]. Le site a été ainsi occupé depuis l’époque romaine jusqu’au XIIIème siècle avec des temps forts au milieu du IIIème siècle et au début du Vème siècle. » Raymond Brulet, La fortification de Hauterecenne à Furfooz, (publication d'histoire de l'Art et d'Archéologie de l'Université catholique de Louvain XIII), 1978",
		hauterecenneSec3Title: "Puis d’une petite forteresse médiévale !",
		hauterecenneSec3Content:
			"Raymond Brulet nous met sur la piste… La forteresse aurait donc évolué au fil du temps ? Et oui, après la chute de l’Empire romain et le départ de ses troupes, le site se fortifie épisodiquement. Nous n’avons que peu d’informations quant aux habitants qui s’y installèrent, en revanche la présence d’une maison forte et l’ajout de remparts suggèrent l’installation d’un petit seigneur régional. À la fin du XIIIème siècle, le site perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		hauterecenneEncart2Title: "Savez-vous ce qu’est une maison forte ?",
		hauterecenneEncart2Content:
			"La maison forte est l’ancêtre des châteaux forts. De taille modeste, il n’est possible d’y pénétrer que par le 1er étage. Ainsi, si la maison est attaquée, l’escalier peut être retiré et les occupants protégés !",
		hauterecenneSec4Title:
			"Une superposition d’époques, comment faire pour les distinguer ?",
		hauterecenneSec4Content:
			"Pour retracer l’histoire et l’évolution de l’occupation humaine sur un site, l’archéologue outillé, mais s’entoure de spécialistes. Dans le cas du plateau fortifié, il faut pouvoir distinguer les époques, alors qu’elles se superposent. On travaille alors sur une chronologie relative et on fait appel à des comparaisons site par site. Ce long cheminement a permis de faire une découverte importante : la présence de deux murs de barrage.",
		hauterecenneSec5Title: "L’affaire des deux murs de barrage",
		hauterecenneSec5Content:
			"Et oui, les différentes époques sont visibles sur les murs ! Le premier mur de barrage, le plus extérieur, date du début du Bas-Empire romain. Très étroit et construit selon la même technique que les thermes, cela laisse à penser qu’il devait être muni soit d’un talus de terre appuyé contre lui, soit d’un parapet en bois, ancré dans le mur. Pour une raison que l’on ignore, un second mur de barrage a été élevé ensuite. Il bénéficie d’une large porte charretière et son épaisseur est cette fois plus commune. Il remonte sans doute au Ve siècle. Lors de la dernière mise en défense du site, à l’époque du Moyen- ge classique, ce mur a été bouché, puis surélevé. Son système de circulation au sommet de l’infrastructure repose sur un série de piliers à arcatures.",
		hauterecenneSec6Title: "Et si on fouillait encore ce lieu ?",
		hauterecenneSec6Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsSubtitle: "Entre découvertes paléontologiques et légende populaire",
		nutonsSec1Title: "Le Trou des nutons, première grotte explorée",
		nutonsSec1Content:
			"En 1864, notre cher Edouard Dupont se lance en premier dans la fouille du trou des Nutons. S’il le choisit comme point de départ de son exploration, c’est parce qu’il présente toutes les caractéristiques idéales à l’implantation d’un campement magdalénien : bien abrité, logé à 40 m au-dessus du lit de la rivière donc proche d’une source d’eau… parfait pour cette population semi-nomade de chasseurs de rennes ou de chevaux !",
		nutonsSec2Title: "Quand l’étude des sols rencontre l’étude des Hommes",
		nutonsSec2Content:
			"Mais comment diable Edouard Dupont tire-t-il autant de conclusions au sujet d’une population si lointaine ? En effectuant ce l’on appelle en archéologie un sondage en archéologie. Sur une toute petite partie de la grotte, l'équipe de Dupont retire les couches successives du sol, jusqu’à 8 m de profondeur pour les étudier. Ces couches sont composées de sédiments, c’est-à-dire de dépôts organiques qui en disent beaucoup sur la période d’occupation et les modes de vie de nos ancêtres. Indéniablement, géologie et archéologie sont intimement liées.",
		nutonsSec3Title: "Des premières grandes découvertes",
		nutonsSec3Content:
			"Edouard Dupont ne s’arrête pas aux sondages ! Il fait dynamiter les stalagmites et les blocs de pierre. Sous ceux-ci, il fait de grandes découvertes : des débris  d’une vingtaine d’espèces animales, des couteaux et des flèches en silex, des pièces de monnaie de l’époque gallo-romaine et divers ustensiles postérieurs. Par contre, il n’y a pas la moindre trace d’ossements humains… Pourtant, tout portait à croire que la découverte de crânes était possible !",
		nutonsSec4Title: "Le %lpaléontologue%l écoute les légendes populaires",
		nutonsSec4Content:
			"Et oui ! Si Edouard Dupont fait le choix de ce trou pour commencer son exploration, c’est aussi car une légende lui est associée… Selon la croyance populaire, les Nutons étaient des nains à l’aspect vieux et chétif, mais actifs, très malicieux et fort habiles de leurs mains. Ils vivaient cachés dans les rochers, les cavernes et les ruines. Pendant la nuit, pour un peu de nourriture et quelques friandises déposées à l’entrée de leurs  gîtes, ces êtres surnaturels réparaient les ustensiles, les objets mobiliers, raccommodaient des haillons et blanchissaient le linge des villageoises. À la belle saison, ils sortaient en nombre pour se livrer à de joyeuses danses dans les prés. Taquins, ils pouvaient être très susceptibles et se vengeaient de ceux qui les bernaient ou cherchaient à découvrir leurs secrets.",
		nutonsEncart1Title: "Une explication scientifique de la légende ?",
		nutonsEncart1Content:
			"Edouard Dupont tente d’expliquer la petite taille des populations de Furfooz : « Les hommes de Grenelle, et surtout ceux de Furfooz, étaient de petite taille. Les premiers alignaient encore une moyenne d’1m62, mais les seconds descendaient à 1m53. C’est presque exactement la taille moyenne des Lapons. Toutefois, cette stature réduite n’excluait ni la vigueur ni l’agilité nécessaire aux populations sauvages. Les os des membres et du tronc sont robustes, et les saillies, les dépressions de leur surface, accusent un développement musculaire très prononcé. À part cette robusticité générale, supérieure à ce que l’on rencontre habituellement, les squelettes des hommes de Furfooz et de Grenelle ressemblent fort à celui des hommes d’aujourd’hui » (Edouard Dupont, « Sur les crânes de Furfooz », Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"Aussi mystérieux qu’emblématique, le Trou des Nutons a toujours de quoi vous surprendre ! Continuez votre exploration de la réserve pour découvrir bien d’autres secrets.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalSubtitle: "Entre habitat et sépulture",
		frontalSec1Title:
			"S’il est appelé Trou du Frontal, c’est grâce à une découverte !",
		frontalSec1Subtitle:
			"À votre avis, pourquoi ce nom ? Tout simplement car notre ami Edouard Dupont, en fouillant le trou en 1864, met la main dès les premiers coups de pioche sur un os frontal appartenant à un adolescent. Cette grande découverte est un événement dans le monde de la recherche et est annonciatrice de bien d’autres…",
		frontalSec2Title:
			"la découverte de deux crânes très différents, une énigme à élucider",
		frontalSec2Subtitle:
			"Le 10 décembre de la même année, le paléontologue dégage, en présence d’invités de marque, deux crânes humains intacts conservés dans un ossuaire. Cela ouvre un champ d’hypothèses immense quant à la période d’occupation de ces grottes, mais aussi aux modes de vie de nos ancêtres.",
		frontalEncart1Title: "Récit de fouilles par Dupont",
		frontalEncart1Content:
			"“Cette petite caverne, tant par ses données géologiques que par les lumières qu’elle a répandues sur l’ethnographie ancienne, est la plus importante qui ait encore été fouillée dans notre pays. Il est certain que si l’explorateur eût souvent vu ses recherches couronnées d’un succès aussi complet, les laborieux travaux, entrepris dans notre région, eussent été fort abrégés, et il resterait aujourd’hui bien peu de chose à dévoiler sur les habitants de la Belgique à l’époque quaternaire. [...] Nous examinerons plus loin les données que les restes des repas dans l’abri extérieur et l’amas d’ossements humains nous fournissent sur les coutumes funéraires de l’antique peuplade.” \n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Se tromper fait partie de la recherche paléontologique !",
		frontalSec3Subtitle:
			"En étudiant ces ossements, Dupont les date à tort de l’époque des chasseurs de rennes. Mais être paléontologue c’est aussi se tromper ! Des études plus approfondies sur ces crânes et de l’ensemble des matériaux découverts sur le site ont mis au jour une confusion de la part de Dupont entre des traces de Magdaléniens, les fameux chasseurs, et celles de populations vivant au Néolithique.",
		frontalEncart2Title:
			" Quand la théorie de Darwin éclaire le monde paléontologique",
		frontalEncart2Content:
			"Les fouilles entreprises par Dupont sont considérées comme le premier argument anatomique en faveur des théories de  Darwin, retranscrites dans son ouvrage L’origine des espèces publié en 1859, quelques années avant ses découvertes. Grâce à ces crânes, il comprend l’évolution de l’espèce humaine en fonction des périodes d’occupation. Car oui, des Hommes de deux ères vivaient au trou du Frontal !",
		frontalSec4Title: "Des Hommes de deux ères : la vie, la mort",
		frontalSec4Subtitle:
			"Au Trou du Frontal, les fouilles archéologiques ont mis au jour à la fois des silex taillés datant de l’époque %lMagdalénienne%l, mais aussi de nombreux ossements, d’au moins 16 individus (hommes, femmes et enfants) situés nettement au-dessus de la couche du niveau magdalénien. Tout porte à croire qu’il s’agit-là de vestiges d’une sépulture datant du Néolithique moyen. Près de 10 000 ans les séparent et pourtant, tous ont choisi le trou du Frontal pour refuge !",
		frontalSec5Title: "Vivre et mourir au Trou du Frontal",
		frontalSec5Subtitle:
			"L’amas d’ossements suggère un espace funéraire plutôt qu’un habitat. En effet, le procédé d’inhumation, c’est-à-dire d’enterrer ses morts s’étend à tout le territoire actuel de la Belgique pendant le Néolithique, il y a 7000 ans. Le Trou du Frontal en est un parfait exemple puisqu’il est même fermé par une dalle, comme on le ferait pour une tombe, ce qui confirme qu’il s’agit-là d’une sépulture secondaire datant du Néolithique moyen.",
		frontalSec6Title:
			"Du trou du Frontal au trou de la Mâchoire… il n’y a que 10 mètres à parcourir !",
		frontalSec6Subtitle:
			"La pratique funéraire est très présente sur le site de Furfooz. À quelques pas de trou du Frontal, le trou de la Mâchoire avait sans doute la même fonction puisqu’on y a découvert cinq squelettes dans un état remarquable. Et, nous le savons, étudier les morts c’est comprendre la vie !\nPassez à la buvette située non loin du trou du Frontal pour profiter pleinement des beautés de la nature.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleSubtitle:
			"Une mine d’informations sur l’histoire de l’humanité !",
		goldenPaddleSec1Title:
			"Pourquoi diable étudier la roche pour en apprendre sur les Hommes ?",
		goldenPaddleSec1Subtitle:
			"“Les études de cette nature, qui puisent leurs éléments dans les sciences géologiques, sont donc du ressort exclusif des sciences naturelles. Par ces investigations, la géologie donne la main à l’histoire et l’aide à restaurer le passé le l’humanité.” Edouard Dupont, Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse,1872\n\nCes quelques mots de Edouard Dupont transmettent bien l’importance de l’étude de la roche, la sédimentation, dans la compréhension de l’histoire de l’humanité. On vous explique.",
		goldenPaddleSec2Title:
			"La sédimentation, une mine d’or pour les scientifiques !",
		goldenPaddleSec2Subtitle:
			"En décryptant les modifications du milieu naturel, celui que l’on appelle le géomorphologue nous renseigne sur les activités humaines qui l’ont façonné. En étudiant les sédiments, il détermine l’origine des dépôts (alluvions, ruissellements, remblais…) ou leur âge. Il observe aussi la succession des strates de sédiments visible grâce aux changements de couleur ou de texture des sols (argiles, sables, cailloux…). Chaque strate correspondant à une époque, la méthode stratigraphique permet de déterminer la nature et la chronologie des événements intervenus. À la Gatte d’Or, les couches sédimentaires sont très visibles et renseignent aussi bien sur la présence humaine que sur les origines même de Furfooz.",
		goldenPaddleSec3Title:
			"Des fouilles qui détériorent le patrimoine, cela arrive aussi…",
		goldenPaddleSec3Subtitle:
			"Au début du XXe siècle, le site de Furfooz n’est plus habité depuis longtemps mais est un haut lieu touristique payant. Hélas ! Après les nombreuses fouilles de Dupont, rien n’a été prévu pour protéger l’intérieur des grottes. L’afflux des vacanciers n’a fait qu’empirer les choses avec une détérioration à l’intérieur des  grottes dont celle de la Gatte d’or : bris de concrétions calcaires et altération de la teinte des  draperies suite à un mauvais emploi des bougies utilisées pour l’éclairage.",
		goldenPaddleEncart1Title: "La légende raconte…",
		goldenPaddleEncart1Content:
			"La légende du pays raconte qu’une gatte, une chèvre en wallon, vivrait dans les grottes de Furfooz et garderait un trésor… Cette histoire populaire remonte à une lointaine époque, où le tissu manquait et les banques n’existaient pas. Les gens confectionnaient de petits sacs en peau poilue et cachaient leurs deniers où ils pouvaient. Si bien que parfois, ils les oubliaient ou mourraient avec leur secret… Les malheureux sans mémoire font des heureux bien plus tard ! De cette légende naît la fameuse gatte toute d’or vêtue !",
		goldenPaddleSec4Title:
			"Aujourd’hui, la Gatte d’or est un spot naturel incontournable !",
		goldenPaddleSec4Subtitle:
			"Heureusement, l’association Ardenne & Gaume œuvre aujourd’hui pour la préservation de ce patrimoine. La présence humaine se fait quasi invisible et laisse place à un paysage naturel exceptionnel. La vue y est à couper le souffle !",

		/* Reuviau */
		reuviau: "Trou Reuviau",
		reuviauTitle: "Trou Reuviau",
		reuviauSubtitle: "Au Trou Reuviau, on se met à table !",
		reuviauSec1Title: "Reuviau, un trou discret mais riche en savoir",
		reuviauSec1Subtitle:
			"À la fin de votre promenade dans la réserve naturelle de Furfooz, n’oubliez pas de vous arrêter au trou Reuviau !  Modeste, il est pourtant très intéressant pour les paléontologues, notamment pour connaître mieux les pratiques alimentaires des Magdaléniens. On vous explique.",
		reuviauEncart1Title: "Fouilles du Reuviau par E.Dupont",
		reuviauEncart1Content:
			"“Une autre petite caverne, le trou Reuviau, dans un ravin sur le chemin qui conduit de la Lesse à Furfooz, a fourni, dans les mêmes circonstances géologiques, des ossements de cheval, de renne, d’ours, etc., brisés de main d'homme.”\n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "Le repas des Hommes à l’âge du renne",
		reuviauSec2Subtitle:
			"Edouard Dupont, puis bien d’autres paléontologues, ont découvert une série d’ossement de rennes et de chevaux qui portent à croire qu’ils étaient des mets de choix pour ces hommes des cavernes… cela a même donné le nom à l’âge du renne, pour dire ! Des silex mis au jour dans le trou en disent beaucoup sur la façon dont ces hommes dépeçaient les bêtes, aussi bien pour se nourrir que pour se vêtir.",
		reuviauEncart2Title: "Dupont défini l’âge de pierre",
		reuviauEncart2Content:
			"“La chasse leur fournissait leurs aliments et leurs grossiers vêtements ; l’os et surtout la pierre-à-feu leur donnaient des outils et des armes, sans qu’elles eussent pu arriver à produire des métaux ou seulement à en soupçonner l’existence. Aussi a-t-on désigné, sous le nom d’âges de pierre, ces époques lointaines et de longue durée.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "La chasse à la Préhistoire",
		reuviauSec3Subtitle:
			"Durant le paléolithique, le renne, passant en troupeaux dans les vallées, est le gibier de prédilection des hommes préhistoriques. De nombreuses traces de ces chasses ont été retrouvées dans les diverses cavernes de Furfooz. Animal grégaire et migrateur, le renne était sans doute aussi un animal facile à chasser, et à chasser en masse… Mais aussi à faire sécher ! L'ensemble de ces facteurs, étudiés par les paléontologues, nous en apprennent toujours un peu plus sur nos ancêtres !",
		reuviauSec4Title: "L’enquête dans les trous de Furfooz continue !",
		reuviauSec4Subtitle:
			"Vous arrivez à la fin de votre exploration mais, n’en doutez pas, vous n’avez découvert qu’une infime partie de la réserve naturelle de Furfooz ! N’hésitez pas à revenir pour en explorer ses moindres recoins, sa faune et sa flore luxuriantes, protégées depuis près de 70 ans !\nÀ bientôt.",

		furfoozMystery: "Les mystères de Furfooz",
		archaeology: "Archéologie",
		doYouKnow: "Bon à savoir",
		anecdote: "Anecdote",
		edouardDupont: "L'anecdote Dupont",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paléontologue",
		palaeontologistDesc:
			"celui qui étudie le Vivant, qu'il s'agisse d'êtres humains, d'animaux mais aussi de végétaux. Il s’intéresse à l’évolution biologique de ces êtres, sur une période immense qui remonte aux premières traces de vie sur Terre, il y a environ 3,5 milliards d’années.",
		archaeologist: "Archéologue",
		archaeologistDesc:
			"celui qui étudie les civilisations humaines passées à partir de traces matérielles qui en subsistent (bâtiments, statues, pièces de monnaie, tombes, manuscrits, outils de la vie quotidienne…).",
		geologist: "Géologue",
		geologistDesc:
			"celui qui étudie, décrit et explique la nature, l'origine et la situation des roches, des terrains, etc., constituant la croûte terrestre.",
		anthropologist: "Anthropologue",
		anthropologistDesc:
			"celui qui étudie l’homme et son évolution. En archéologie, il analyse les données biologiques et les conditions de vie des individus à partir du squelette. Il enregistre les structures funéraires, la position des défunts et des ossements, les objets associés (bijoux, vêtements, monnaies,…) c’est-à-dire tous les éléments qui lui permettent d’identifier les gestes effectués par les vivants.",
		sediment: "Sédiment",
		sedimentDesc:
			"dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide.",
		experimentalArchaeology: "Archéologie expérimentale",
		experimentalArchaeologyDesc:
			"Branche de l’archéologie, cette discipline consiste à restituer, à travers l’expérimentation, des bâtiments ou objets grâce aux techniques de construction et de fabrication d’époque… l’application de l’archéologie grandeur nature en somme !",
		necropolis: "Nécropole",
		necropolisDesc:
			"Equivalent d’un cimetière à l’Antiquité. Les sépultures peuvent être situées dans des excavations souterraines ou à ciel ouvert.",
		hypocaust: "Hypocauste",
		hypocaustDesc:
			"Local souterrain renfermant un système destiné à chauffer les bains, les salles des thermes.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"Ensemble des théories biologiques de Darwin et de ses disciples selon lesquels la sélection naturelle est le facteur essentiel de la transformation des espèces vivantes.",
		prospecting: "Prospection (archéologie)",
		prospectingDesc:
			"Méthode globale d'appréhension de l'ensemble des traces des aménagements apportés par les sociétés humaines à leur environnement physique et vivant.",
		ossuary: "Ossuaire",
		ossuaryDesc:
			"Amas d’ossements. Cela peut aussi être un bâtiment où les ossements humains sont entassés.",

		palaeolithic: "Paléolithique",
		palaeolithicDesc:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -34 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		magdalenian: "Magdalénien",
		magdalenianDesc:
			"Cette période, aussi appelée par Edouard Dupont l’âge du renne, correspond à la dernière phase du paléolithique supérieur, entre environ 17 000 et 12 000 ans avant notre ère. Encore au cœur d’une ère glaciaire, on y voit l'essor du travail de l'os et le perfectionnement du petit outillage de pierre.",
		neolithic: "Néolithique",
		neolithicDesc:
			"Période de la préhistoire qui appartient à l'époque de la pierre polie et qui s'étend, selon les sites, de - 7 000 à - 2 500 ans.",
		antiquity: "Antiquité",
		antiquityDesc:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen  ge, c’est-à-dire la période entre 3500 av. J.-C. et 476 (date de la dislocation de l’Empire Romain d’Occident).",
		lateRoman: "Bas-Empire",
		lateRomanDesc:
			"Période de l’Empire romain à partir du milieu du IVe siècle jusqu'à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen- ge.",
		classicalMiddleAges: "Moyen-Âge classique",
		classicalMiddleAgesDesc:
			"Aussi appelée Moyen- ge centrale, cette période comprise entre les XI et XIIIe siècles de notre ère se caractérise par l’organisation du système féodal, des grands seigneurs et de la construction de leurs châteaux.",
	},
];
