var options = {
	enableHighAccuracy: true,
	timeout: 20000,
	maximumAge: 0,
};

export const askGeoloc = () => {
	return new Promise<boolean>(async (resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				function(position) {
					resolve(true);
				},
				function(error) {
					resolve(false);
				}, options
			);
		} else {
			resolve(false);
		}
	});
};
