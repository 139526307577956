// React
import { createReducer } from "@reduxjs/toolkit";
// Types
import { APP_FIRSTLAUNCH, APP_LANGUAGE, APP_GEOLOC,/*APP_SENSORS, */IAction } from "../actions/types";

export interface IAppState {
	firstLaunch: boolean;
	language: string;
	geoLoc: boolean;
	//sensorsAllowed: boolean;
}

const initialState: IAppState = {
	firstLaunch: true,
	language: "fr",
	geoLoc: false
	//sensorsAllowed: false,
};

export const reducer = createReducer(initialState, (builder: any) => {
	builder.addCase(APP_FIRSTLAUNCH, (state: any, action: IAction) => {
		state.firstLaunch = action.payload;
	});
	builder.addCase(APP_LANGUAGE, (state: any, action: IAction) => {
		state.language = action.payload;
	});
	builder.addCase(APP_GEOLOC, (state: any, action: IAction) => {
		state.geoLoc = action.payload;
	});
	/*builder.addCase(APP_SENSORS, (state: any, action: IAction) => {
		state.sensorsAllowed = action.payload;
	});*/
});
