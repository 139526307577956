// Components
import { trads } from "src/data/trads";
import { GetTrad } from "../Partials/GetTrad";
// Style
import "./splashscreen.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import { setLanguage } from "src/actions/app";
import { useState } from "react";

export const Splashscreen = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const dispatch = useDispatch();

	const [langSelection, setLangSelection] = useState(false);

	return (
		<div className='splashscreen'>
			<div className='splashscreen-content'>
				<h1 className='splashscreen-title'>
					<span className='splashscreen-title-line1'>
						<GetTrad lang='fr' value='mainTitleLine1' />
					</span>
					<br />
					<span className='splashscreen-title-line2'>
						<GetTrad lang='fr' value='mainTitleLine2' />
					</span>
				</h1>
				<h2 className='splashscreen-subtitle'>
					<GetTrad lang='fr' value='mainSubtitle' />
				</h2>
			</div>
			<div className='splashscreen-start'>
				<div className='splashscreen-start-button' onClick={() => { if (!langSelection) props.next()}}>
					<div className='splashscreen-start-buttonText'>
						<GetTrad lang='fr' value='start' />
						<div className='splashscreen-start-buttonArrow'></div>
					</div>
				</div>
				<div
					className='splashscreen-start-langSelect'
					onClick={() => { if (!langSelection) setLangSelection(true)}}
				>
					<div className='splashscreen-start-langSelectText'>
						<div className='splashscreen-start-langSelectFlag'></div>
						<GetTrad lang={language} value={"lang"} />
						<div className='splashscreen-start-langSelectArrow'></div>
					</div>
				</div>
				{langSelection && (
					<div
						className='splashscreenPopup'
					>
						{trads.map((t: any) => {
							return (
								<div
									className={
										"splashscreenPopupButton " +
										(t.id === language ? "splashscreenPopupButtonSelected" : "")
									}
									onClick={(e) => {
										dispatch(setLanguage(t.id));
										setLangSelection(false);
									}}
								>
									<div
										className={
											"splashscreenPopupButtonText " +
											(t.id === language
												? "splashscreenPopupButtonTextSelected"
												: "")
										}
									>
										<div
											className='splashscreen-start-langSelectFlag'
											style={{ backgroundImage: t.flag }}
										></div>
										<GetTrad lang={t.id} value={"lang"} />
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
