// React
import { configureStore } from "@reduxjs/toolkit";
// App Files

import { reducer as reducer_app, IAppState } from "./reducer_app";
/*import { reducer as reducer_map, IMapState } from "./reducer_map";*/
import { reducer as reducer_game, IGameState } from "./reducer_game";

export interface IRootState {
	app: IAppState;
	game: IGameState;
	/*map: IMapState;*/
}

const store = configureStore({
	reducer: {
		app: reducer_app,
		game: reducer_game,
		/*map: reducer_map,*/
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
