import { GetTrad } from "../Partials/GetTrad";
import "./glossary.css";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";

export const Glossary = () => {
	const { glossary } = useSelector((state: IRootState) => state.game);

	const [cardOpenned, setCardOpenned] = useState<null | number>(null);
	const _cardOpenned = useRef(cardOpenned);

	useEffect(() => {
		_cardOpenned.current = cardOpenned;

		if (cardOpenned !== null) {
			let cardElem = document.getElementById("glossaryCard_" + cardOpenned);
			let cardTextDElem = document.getElementById(
				"glossaryCard-subtitleD-" + cardOpenned
			);
			let cardTextElem = document.getElementById(
				"glossaryCard-subtitle-" + cardOpenned
			);

			if (cardTextElem && cardTextDElem) {
				(cardTextDElem as any).style.opacity = "1";
				(cardTextElem as any).style.opacity = "1";
				setTimeout(() => {
					if (cardElem) {
						cardElem.style.maxHeight = "300px";
					}
				}, 50);
			}
		} else {
			for (let i = 0; i < glossary.length; i++) {
				let cardElem = document.getElementById("glossaryCard_" + i);
				let cardTextDElem = document.getElementById(
					"glossaryCard-subtitleD-" + cardOpenned
				);
				let cardTextElem = document.getElementById(
					"glossaryCard-subtitle-" + cardOpenned
				);

				if (cardElem) {
					cardElem.style.maxHeight = "0px";
					setTimeout(() => {
						if (cardTextElem && cardTextDElem) {
							(cardTextElem as any).style.opacity = "0";
							(cardTextDElem as any).style.opacity = "0";
						}
					}, 50);
				}
			}
		}
		console.log("Card openned :", cardOpenned);
	}, [cardOpenned]);

	let index = -1;

	return (
		<div className='glossary'>
			<div className='glossary-header'>
				<div className='glossary-title'>
					<GetTrad lang='fr' value='glossary' />
				</div>
				<div className='glossary-subtitle'>
					<GetTrad lang='fr' value='explanationGlossaryLine1' />
					<br />
					<GetTrad lang='fr' value='explanationGlossaryLine2' />
				</div>
			</div>
			<div className='glossary-content'>
				<div
					className='glossary-body'
					style={{ height: glossary.length * 64 + "px" }}
				>
					{glossary.map((g: any) => {
						index++;
						return (
							<div
								className='glossaryCard'
								key={"glossaryCard_" + g.name + "_" + index}
								id={"glossaryCard_" + index}
								style={{
									bottom: glossary.length * 64 + 32 - index * 64 + "px",
								}}
								onClick={(e: any) => {
									if (g.found) {
										let newIndex = e.target.id.slice(
											"glossaryCard_".length,
											e.target.id.length
										);
										console.log("Click on " + newIndex);
										if (_cardOpenned.current !== null) {
											setCardOpenned(null);
										} else {
											setCardOpenned(newIndex);
										}
									}
								}}
							>
								<div
									className='glossaryCard-title'
									style={{ opacity: g.found ? "1" : "0.2" }}
								>
									<GetTrad lang='fr' value={g.name} />
								</div>

								<div
									id={"glossaryCard_content_" + index}
									style={{ opacity: g.found ? "1" : "0.2" }}
									className='glossaryCard-content'
								>
									<div
										className='glossaryCard-subtitle'
										id={"glossaryCard-subtitleD-" + index}
										style={{ opacity: "0" }}
									>
										<GetTrad lang='fr' value={"unlockTo"} />
										{g.unlock && g.unlock !== "" ? (
											<GetTrad lang='fr' value={g.unlock} />
										) : (
											"---"
										)}
									</div>

									<div
										id={"glossaryCard-subtitle-" + index}
										className='glossaryCard-subtitle'
										style={{
											opacity: "0",
										}}
									>
										<GetTrad lang='fr' value={g.desc} />
									</div>
								</div>
							</div>
						);
					})}
					<div
						className='glossaryCard'
						style={{
							transform: 'translateY(50%)',
							position: "absolute",
							bottom: "0px",
							padding: "124px 0 80px 0",
							borderRadius: "0",
							boxShadow: "none"
						}}
					></div>
					{/* <div className="glossaryCard-blank" style={{ bottom: glossary.length * 64 - (index + 1) * 64 + "px" }}></div> */}
				</div>
			</div>
		</div>
	);
};
