// Redux
import { createAction } from "@reduxjs/toolkit";
// Types
import {
	GAME_POISPROGRESS,
	GAME_SETFOUNDPOI,
	GAME_GLOSSARYPROGRESS,
	GAME_SETFOUNDGLOSSARY,
	GAME_SETPLACEFOUNDGLOSSARY,
	GAME_POISRESET,
} from "./types";

export const resetProgress = createAction<void>(GAME_POISRESET);
export const setPoisProgress = createAction<boolean>(GAME_POISPROGRESS);
export const setFoundPoi = createAction<string>(GAME_SETFOUNDPOI);

export const setGlossaryProgress = createAction<boolean>(GAME_GLOSSARYPROGRESS);
export const setFoundGlossary = createAction<string>(GAME_SETFOUNDGLOSSARY);
export const setPlaceFoundGlossary = createAction<[string, string]>(
	GAME_SETPLACEFOUNDGLOSSARY
);
