// Components
import { subtimeline, timeline } from "src/data/timeline";
import { GetTrad } from "../Partials/GetTrad";
// Style
import "./timeline.css";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import React from "react";

export const Timeline = () => {
	const { language } = useSelector((state: IRootState) => state.app);

	let dateWrote = new Date().getFullYear() - (new Date().getFullYear() % 100);

	return (
		<div className='timeline'>
			<div className='timeline-header'>
				<div className='timeline-title'>
					<GetTrad lang='fr' value='timelineTitle' />
				</div>
				<div className='timeline-subtitle'>
					<GetTrad lang='fr' value='explanationTimeline' />
				</div>
			</div>
			<div className='timeline-content'>
				<div
					className='timeline-body'
					style={{
						height:
							Math.abs(
								timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0]
									.start
							) +
							new Date().getFullYear() +
							360 +
							"px",
					}}
				>
					<div className='timeline-years'>
						<div
							className='timeline-millenniums'
							style={{
								backgroundPosition:
									"0% " + (new Date().getFullYear() - 2000) + "px",
							}}
						></div>
						<div
							className='timeline-centuries'
							style={{
								backgroundPosition:
									"0% " + (new Date().getFullYear() - 2000) + "px",
							}}
						></div>
						<div
							className='timeline-decades'
							style={{
								backgroundPosition:
									"0% " + (new Date().getFullYear() - 2000) + "px",
							}}
						></div>

						{[
							dateWrote,
							dateWrote - 100,
							dateWrote - 200,
							dateWrote - 300,
							dateWrote - 400,
							dateWrote - 500,
						].map((date: number, index: number) => (
							<div
								className='timeline-yearName'
								style={{
									top: new Date().getFullYear() - 2000 + index * 100 + "px",
									opacity: 1 - 0.2 * index,
								}}
							>
								{date}
							</div>
						))}
					</div>

					{timeline.map((t: any, i: number) => (
						<div
							id={t.id}
							className='timeline-period'
							style={{
								zIndex: 100 - i,
								top: new Date().getFullYear() - t.end + 16 + "px",
								height: Math.abs(t.start - t.end) + "px",
								margin: "0 0 0 0",
								// (i === (timeline.length - 1) ? 16 : 0)
							}}
						>
							{t.topMsg && (
								<div className='timeline-period-top'>
									{t.topMsg.desc && (
										<React.Fragment>
											<div
												className='timeline-period-top-delimiter1'
												style={{ backgroundColor: t.topMsg.color }}
											></div>
											<div className='timeline-period-top-text'>
												<div className='timeline-period-top-textContent'>
													<div className='timeline-period-top-textContent-year'>
														{t.topMsg.year}
													</div>
													<div
														className='timeline-period-top-textContent-desc'
														style={{ color: t.topMsg.color }}
													>
														<GetTrad lang={language} value={t.topMsg.desc} />
													</div>
												</div>
												<div
													className='timeline-period-top-delimiter2'
													style={{ backgroundColor: t.topMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.topMsg.desc && (
										<div
											className='timeline-period-top-delimiter3'
											style={{ backgroundColor: t.topMsg.color }}
										></div>
									)}
								</div>
							)}
							{t.content &&
								t.content.map((tc: any) => {
									switch (tc.type) {
										case "card":
											return (
												<div
													className='timeline-cardName'
													style={{
														margin: t.offset
															? 16 + t.offset + "px 16px 16px 32px"
															: "16px 16px 16px 32px",
													}}
												>
													{tc.desc && (
														<GetTrad lang={language} value={tc.desc} />
													)}
													{tc.scrollArrows?.expl && (
														<div className='timeline-scrollArrows'>
															<GetTrad
																lang={language}
																value={"scrollArrowsExpl"}
															/>
														</div>
													)}
													{tc.scrollArrows && (
														<div className='timeline-scrollArrows'>
															<div
																className='timeline-scrollArrow'
																onClick={() => {
																	let elem = document.getElementById(
																		tc.scrollArrows.top
																	);

																	if (elem) {
																		let target = elem.style.top
																			.split(" ")[0]
																			.slice(
																				0,
																				elem.style.top.split(" ")[0].length - 2
																			);

																		let elems =
																			document.getElementsByClassName(
																				"timeline-content"
																			);

																		for (let elem of elems) {
																			elem.scrollTo(0, parseInt(target) + 280);
																		}
																	}
																}}
															>
																<div className='timeline-scrollArrowTop'></div>
															</div>
															<div
																className='timeline-scrollArrow'
																onClick={() => {
																	let elem = document.getElementById(
																		tc.scrollArrows.bottom
																	);

																	if (elem) {
																		let target = elem.style.top.slice(
																			0,
																			elem.style.top.split(" ")[0].length - 2
																		);

																		console.log("target", target);

																		let elems =
																			document.getElementsByClassName(
																				"timeline-content"
																			);

																		for (let elem of elems) {
																			elem.scrollTo(0, parseInt(target) + 280);
																		}
																	}
																}}
															>
																<div className='timeline-scrollArrowBottom'></div>
															</div>
														</div>
													)}
												</div>
											);
										default:
											return <div className='timeline-cardName'></div>;
									}
								})}
							{t.bottomMsg && (
								<div className='timeline-period-bottom'>
									{t.bottomMsg.desc && (
										<React.Fragment>
											<div
												className='timeline-period-top-delimiter1'
												style={{ backgroundColor: t.bottomMsg.color }}
											></div>
											<div className='timeline-period-top-text'>
												<div className='timeline-period-top-textContent'>
													<div className='timeline-period-top-textContent-year'>
														{t.bottomMsg.year}
													</div>
													<div
														className='timeline-period-top-textContent-desc'
														style={{ color: t.bottomMsg.color }}
													>
														<GetTrad lang={language} value={t.bottomMsg.desc} />
													</div>
												</div>
												<div
													className='timeline-period-top-delimiter2'
													style={{ backgroundColor: t.bottomMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.bottomMsg.desc && (
										<div
											className='timeline-period-top-delimiter3'
											style={{ backgroundColor: t.bottomMsg.color }}
										></div>
									)}
								</div>
							)}

							<div
								className='timeline-cardName-periodColor'
								style={{ backgroundColor: t.color }}
							>
								<div className='timeline-cardName-periodColorName'>
									<GetTrad lang={language} value={t.name} /> { "(" + t.start + ' - ' + t.end + ")"}
								</div>
							</div>
						</div>
					))}
					<div
						id={"timeline-end"}
						className='timeline-period'
						style={{
							zIndex: 0,
							top:
								new Date().getFullYear() +
								Math.abs(
									timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0]
										.start
								) +
								16 +
								"px",
							height: "0px",
							margin: "0 0 0 0",
						}}
					></div>
					{subtimeline.map((t: any, i: number) => (
						<div
							id={t.id}
							className='subtimeline-period'
							style={{
								zIndex: 200 - i,
								top: new Date().getFullYear() - t.end - 16 + "px",
								height: Math.abs(t.start - t.end) + 32 + "px",
								margin: "16px 0 0 0",
							}}
						>
							{t.topMsg && (
								<div className='subtimeline-period-top'>
									{t.topMsg.desc && (
										<React.Fragment>
											<div
												className='subtimeline-period-top-delimiter1'
												style={{ backgroundColor: t.topMsg.color }}
											></div>
											<div className='subtimeline-period-top-text'>
												<div className='subtimeline-period-top-textContent'>
													<div className='subtimeline-period-top-textContent-year'>
														{t.topMsg.year}
													</div>
													<div
														className='subtimeline-period-top-textContent-desc'
														style={{ color: t.topMsg.color }}
													>
														<GetTrad lang={language} value={t.topMsg.desc} />
													</div>
												</div>
												<div
													className='subtimeline-period-top-delimiter2'
													style={{ backgroundColor: t.topMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.topMsg.desc && (
										<div
											className='subtimeline-period-top-delimiter3'
											style={{ backgroundColor: t.topMsg.color }}
										></div>
									)}
								</div>
							)}
							{t.content &&
								t.content.map((tc: any) => {
									switch (tc.type) {
										case "card":
											return (
												<div className='subtimeline-cardName'>
													{tc.desc && (
														<GetTrad lang={language} value={tc.desc} />
													)}
													{tc.scrollArrows?.expl && (
														<div className='timeline-scrollArrows'>
															<GetTrad
																lang={language}
																value={"scrollArrowsExpl"}
															/>
														</div>
													)}
													{tc.scrollArrows && (
														<div className='timeline-scrollArrows'>
															<div
																className='timeline-scrollArrow'
																onClick={() => {
																	let elem = document.getElementById(
																		tc.scrollArrows.top
																	);

																	if (elem) {
																		let target = elem.style.top
																			.split(" ")[0]
																			.slice(
																				0,
																				elem.style.top.split(" ")[0].length - 2
																			);

																		let elems =
																			document.getElementsByClassName(
																				"timeline-content"
																			);

																		for (let elem of elems) {
																			elem.scrollTo(0, parseInt(target) + 280);
																		}
																	}
																}}
															>
																<div className='timeline-scrollArrowTop'></div>
															</div>
															<div
																className='timeline-scrollArrow'
																onClick={() => {
																	let elem = document.getElementById(
																		tc.scrollArrows.bottom
																	);

																	if (elem) {
																		let target = elem.style.top.slice(
																			0,
																			elem.style.top.split(" ")[0].length - 2
																		);

																		console.log("target", target);

																		let elems =
																			document.getElementsByClassName(
																				"timeline-content"
																			);

																		for (let elem of elems) {
																			elem.scrollTo(0, parseInt(target) + 280);
																		}
																	}
																}}
															>
																<div className='timeline-scrollArrowBottom'></div>
															</div>
														</div>
													)}
												</div>
											);
										default:
											return <div className='subtimeline-cardName'>Test</div>;
									}
								})}
							{t.bottomMsg && (
								<div className='subtimeline-period-bottom'>
									{t.bottomMsg.desc && (
										<React.Fragment>
											<div
												className='subtimeline-period-top-delimiter1'
												style={{ backgroundColor: t.bottomMsg.color }}
											></div>
											<div className='subtimeline-period-top-text'>
												<div className='subtimeline-period-top-textContent'>
													<div className='subtimeline-period-top-textContent-year'>
														{t.bottomMsg.year}
													</div>
													<div
														className='subtimeline-period-top-textContent-desc'
														style={{ color: t.bottomMsg.color }}
													>
														<GetTrad lang={language} value={t.bottomMsg.desc} />
													</div>
												</div>
												<div
													className='subtimeline-period-top-delimiter2'
													style={{ backgroundColor: t.bottomMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.bottomMsg.desc && (
										<div
											className='subtimeline-period-top-delimiter3'
											style={{ backgroundColor: t.bottomMsg.color }}
										></div>
									)}
								</div>
							)}

							<div
								className='subtimeline-cardName-periodColor'
								style={{ backgroundColor: t.color }}
							>
								<div className='subtimeline-cardName-periodColorName'>
									<GetTrad lang={language} value={t.name} />
								</div>
							</div>
						</div>
					))}
					<div className='timeline-bottomPage'>
						<div className='timeline-bottomPageText'>
							<GetTrad lang={language} value='timelineBottom' />
						</div>
						<div
							className='timeline-bottomPageButton'
							onClick={() => {
								let elems = document.getElementsByClassName("timeline-content");

								for (let elem of elems) {
									elem.scrollTo(0, 0);
								}
							}}
						>
							<div className='timeline-bottomPageButtonText'>
								<GetTrad lang={language} value='timelineBackFutur' />
								<div className='timeline-bottomPageButtonArrow'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
