export const timeline = [
	{
		id: "modern",
		name: "timelineModern",
		end: new Date().getFullYear(),
		start: 1492,
		color: "#B191AA",
		topMsg: {
			color: "#B191AA",
			year: new Date().getFullYear(),
			desc: "today",
		},
		content: [
			{
				type: "card",
				desc: "littleSticksOnSide",
			},
		],
		bottomMsg: null,
	},
	{
		id: "classicalMiddleAges",
		name: "timelineClassicalMiddleAges",
		end: 1492,
		start: 476,
		color: "#92C6A4",
		topMsg: {
			color: "#92C6A4",
			year: 1492,
			desc: "americaDiscover",
		},
		content: [
			{
				type: "card",
				desc: "classicalMiddleAgesExpl",
			},
		],
		bottomMsg: null,
	},
	{
		id: "antiquity",
		name: "timelineAntiquity",
		end: 476,
		start: -3300,
		color: "#9D7599",
		topMsg: null,
		offset: 126,
		content: [
			{
				type: "card",
				desc: "antiquityDuration",
				scrollArrows: {
					expl: true,
					top: "antiquity",
					bottom: "neolithicInside"
				}
			}
		],
		bottomMsg: null,
	},
	{
		id: "paleolithic",
		name: "timelinePaleolithic",
		end: -10000,
		start: -34000,
		color: "#E0A381",
		topMsg: {
			color: "#E0A381",
			year: -10000,
			desc: "endPeriod",
		},
		content: [
			{
				type: "card",
				desc: "paleolithicDuration",
				scrollArrows: {
					expl: false,
					top: "neolithicInside",
					bottom: "magdalenianInside"
				}
			}
		],
		bottomMsg: {
			color: "#E0A381",
			year: -34000,
			desc: "startPeriod",
		},
	},
];

export const subtimeline = [
	{
		id: "classicalMiddleAgesInside",
		name: "timelineClassicalMiddleAges",
		end: 1200,
		start: 1000,
		color: "#91AFB1",
		topMsg: {
			color: "#91AFB1",
			year: 1200,
			desc: "classicalMiddleAgesStart",
		},
		content: [
			{
				type: "card",
				desc: "classicalMiddleAgesExpl",
				scrollArrows: {
					expl: false,
					top: "antiquity",
					bottom: "timeline-end"
				}
			},
		],
		bottomMsg: {
			color: "#91AFB1",
			year: 1000,
			desc: "classicalMiddleAgesEnd",
		},
	},
	{
		id: "antiquityInside",
		name: "timelineAntiquity",
		end: 476,
		start: 350,
		color: "#ED945F",
		topMsg: {
			color: "#92C6A4",
			year: 476,
			desc: "romanFall",
		},
		content: [
			{
				type: "card",
				desc: "basEmpireExpl",
			},
		],
		bottomMsg: {
			color: "#ED945F",
			year: 350,
			desc: "startPeriod",
		},
	},
	{
		id: "neolithicInside",
		name: "timelineNeolithic",
		end: -2500,
		start: -7000,
		color: "#C4BC95",
		topMsg: {
			color: "#C4BC95",
			year: -2500,
			desc: "endPeriod",
		},
		content: [
			{
				type: "card",
				desc: "neolithicDuration",
				scrollArrows: {
					expl: false,
					top: "antiquity",
					bottom: "paleolithic"
				}
			},
		],
		bottomMsg: {
			color: "#C4BC95",
			year: -7000,
			desc: "startPeriod",
		},
	},
	{
		id: "magdalenianInside",
		name: "timelineMagdalenian",
		end: -12000,
		start: -7000,
		color: "#214A72",
		topMsg: {
			color: "#214A72",
			year: -2500,
			desc: "endPeriod",
		},
		content: [
			{
				type: "card",
				desc: "magdalenianDuration",
				scrollArrows: {
					expl: false,
					top: "paleolithic",
					bottom: "timeline-end"
				}
			},
		],
		bottomMsg: {
			color: "#214A72",
			year: -7000,
			desc: "startPeriod",
		},
	},
];
